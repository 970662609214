import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { parameters } from 'pages/Parameters/constants'

import Table from './Table'
import Header from './Header'
import { Container } from './styles'

const base64regex =
  /^([\da-zA-Z+/]{4})*(([\da-zA-Z+/]{2}==)|([\da-zA-Z+/]{3}=))?$/

const ParametersEdit: React.FC = () => {
  const navigate = useNavigate()
  const { b64data } = useParams()

  const [param, setParam] = useState('')
  const [inputs, setInputs] = useState({})

  const decode = useCallback((base64: string) => {
    return window.atob(window.atob(base64))
  }, [])

  const setupPage = useCallback(() => {
    if (!b64data || !b64data.includes('@')) {
      return navigate('/parameters')
    }

    const [paramBase64, inputsBase64] = b64data.split('@')

    if (!base64regex.test(paramBase64) || !base64regex.test(inputsBase64)) {
      return navigate('/parameters')
    }

    const paramDecoded = decode(paramBase64)

    if (!parameters.includes(paramDecoded)) {
      return navigate('/parameters')
    }

    const inputsDecoded = JSON.parse(decode(inputsBase64))

    setParam(paramDecoded)
    setInputs(inputsDecoded)
  }, [b64data, decode, navigate])

  useEffect(setupPage, [setupPage])

  return (
    <Container>
      <Header param={param} />
      <Table param={param} inputs={inputs} />
    </Container>
  )
}

export default ParametersEdit

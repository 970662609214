import { useMemo } from 'react'

import TableBackend from 'components/TableBackend'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'

import Input from './Input'
import LoadingScreen from 'components/LoadingScreen'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  loading: boolean
  options: IOption[]
}

interface ICellProps {
  row: IRow
}

const Table: React.FC<IProps> = ({
  rows,
  loading,
  options,
  page,
  limit,
  total,
  setPage,
  totalPages,
}) => {
  const cols = useMemo(() => {
    return [
      {
        Header: 'Nome Cluster',
        accessor: 'cluster_name',
      },
      {
        Header: 'Departamento',
        accessor: 'department',
      },
      {
        Header: 'Código Capitão',
        accessor: 'captain_sku',
      },
      {
        Header: 'Descrição do Produto',
        accessor: 'description',
      },
      {
        Header: 'Estratégia do Item',
        accessor: ({ row }: ICellProps) => (
          <Input row={row} options={options} />
        ),
      },
    ]
  }, [options])

  if (loading || options.length === 0) return <LoadingScreen />
  return (
    <TableBackend
      rows={rows}
      cols={cols}
      page={page}
      limit={limit}
      total={total}
      setPage={setPage}
      totalPages={totalPages}
    />
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿

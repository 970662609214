import { useState } from 'react'

import Cards from './Cards'
import Table from './Table'
import Header from './Header'
import IFilters from './types/IFilters'
import { Container } from './styles'

const ApprovalPriceBaseTableV2: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)

  return (
    <Container>
      <Header filters={filters} setFilters={setFilters} />
      <Cards />
      <Table filters={filters} />
    </Container>
  )
}

export default ApprovalPriceBaseTableV2

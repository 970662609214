import { Flex } from '@chakra-ui/react'
import { useMemo } from 'react'

interface IProps {
  active: number
}

const Status: React.FC<IProps> = ({ active }) => {
  const color = useMemo(() => {
    return active ? 'green' : 'red'
  }, [active])

  return <Flex w={4} h={4} borderRadius="50%" bgColor={`${color}.500`} />
}

export default Status

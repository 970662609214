import { useCallback } from 'react'
import { HStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { HiAdjustments as ParametersSvg } from 'react-icons/hi'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'

import CreateButton from './CreateButton'
import ArredondamentoCreate from './ArredondamentoCreate'
import PrecosDeMercadoUpload from './PrecosDeMercadoUpload'
import { Container } from './styles'

interface IProps {
  param: string
}

const Header: React.FC<IProps> = ({ param }) => {
  const navigate = useNavigate()

  const onBack = useCallback(() => {
    navigate('/parameters')
  }, [navigate])

  return (
    <Container>
      <PageTitle
        title="Parâmetros"
        linkLabel={param}
        icon={<ParametersSvg />}
      />
      <HStack spacing={4}>
        {param === 'Cluster de Preço' && <CreateButton />}
        {param === 'Arredondamento' && <ArredondamentoCreate />}
        {param === 'Upload de Preços' && <PrecosDeMercadoUpload />}
        <Button onClick={onBack}>Voltar</Button>
      </HStack>
    </Container>
  )
}

export default Header

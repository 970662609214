import { useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import api from 'services/api'
import LoadingModalButton from 'components/LoadingModalButton'
import Button from 'components/Button'

const ConfirmButton = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancelRef = useRef(null)

  const onConfirm = useCallback(() => {
    const url = '/requests/finish'
    const req = { request_id: +id! }

    return api
      .post(url, req)
      .then(() => {
        navigate('/approval')
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
      })
  }, [id, navigate, toast])

  return (
    <>
      <Button onClick={onOpen}>Confirmar</Button>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar
            </AlertDialogHeader>
            <AlertDialogBody>Por favor confirme sua ação.</AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justify="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <LoadingModalButton
                  promise={onConfirm}
                  message="Confirmando, por favor aguarde..."
                >
                  Confirmar
                </LoadingModalButton>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ConfirmButton

import { Fragment } from 'react'
import { Divider, Flex, Grid, Text } from '@chakra-ui/react'

interface IProps {
  data?: { [key: string]: any }
}

const cols = [
  { Header: 'Quantidade Vendida', accessor: 'quantidade_vendida' },
  { Header: 'Market Share do Produto', accessor: 'market_share_do_produto' },
  { Header: 'Preço Médio Ponderado', accessor: 'preco_medio_ponderado' },
]

const HistoricData: React.FC<IProps> = ({ data }) => {
  if (!data) return null
  return (
    <Flex
      w="100%"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bgColor="#f5f6f8"
      overflow="hidden"
    >
      <Text fontWeight="semibold" fontSize="sm" p="1rem" bgColor="#fff">
        Dados Históricos
      </Text>
      <Divider />
      <Grid
        w="100%"
        p="1rem"
        templateColumns="2fr repeat(4, 1fr)"
        gap="0.25rem"
      >
        <Flex w="100%">
          <Text></Text>
        </Flex>
        <Flex w="100%">
          <Text fontWeight="semibold">M-2</Text>
        </Flex>
        <Flex w="100%">
          <Text fontWeight="semibold">M-1</Text>
        </Flex>
        <Flex w="100%" fontWeight="bold">
          <Text fontWeight="semibold">M-0</Text>
        </Flex>
        <Flex w="100%">
          <Text fontWeight="bold" color="orange.500">
            M+1
          </Text>
        </Flex>
        {cols.map((col) => (
          <Fragment key={col.Header}>
            <Flex w="100%">
              <Text fontWeight="semibold">{col.Header}</Text>
            </Flex>
            <Flex w="100%">
              <Text>
                {data[`${col.accessor}_m2`]
                  .toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Text>
            </Flex>
            <Flex w="100%">
              <Text>
                {data[`${col.accessor}_m1`]
                  .toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Text>
            </Flex>
            <Flex w="100%">
              <Text fontWeight="bold">
                {data[`${col.accessor}_m0`]
                  .toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Text>
            </Flex>
            <Flex w="100%">
              <Text fontWeight="semibold" color="orange.500">
                {data[`${col.accessor}_m+1`]
                  .toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Text>
            </Flex>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  )
}

export default HistoricData

import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  font-size: 0.7rem;
  font-weight: bold;
`

export const idpCols = [
  'Data Período Início',
  'Data Período Fim',
  'EAN',
  'Descrição Concorrente',
  'UF',
  'Canal do PDV',
  'Preço Ponderado',
  'Preço Regular',
  'Fonte Pesquisa',
]

export const idpRows = [
  {
    'Data Período Início': '01/10/2022',
    'Data Período Fim': '17/10/2022',
    EAN: '10000000001',
    'Descrição Concorrente': '2 - LA DE AÇO ASSOLAN 60G',
    UF: 'SP',
    'Canal do PDV': 'VAREJO',
    'Preço Ponderado': '2,37',
    'Preço Regular': '2,49',
    'Fonte Pesquisa': 'Promotor',
  },
]

export const pricepointCols = [
  'Data Período Início',
  'Data Período Fim',
  'Cap SKU',
  'Descrição',
  'UF',
  'Canal do PDV',
  'Preço Ponderado',
  'Preço Regular',
]

export const pricePointRows = [
  {
    'Data Período Início': '01/10/2022',
    'Data Período Fim': '17/10/2022',
    'Cap SKU': '5004',
    Descrição: 'LIMPOL CRISTAL 24/500 ML',
    UF: 'SP',
    'Canal do PDV': 'VAREJO',
    'Preço Ponderado': '1,36',
    'Preço Regular': '1,49',
  },
]

export const columns = [
  {
    Header: 'Canal Pai',
    accessor: 'is_captain',
  },
  {
    Header: 'Canal Pricing',
    accessor: 'name',
  },
  {
    Header: 'Relatividade',
    accessor: 'relatividade',
  },
]

import { memo } from 'react'
import { Flex, Text } from '@chakra-ui/react'

interface IProps {
  label: string
  children: Children
}

const SubSection: React.FC<IProps> = ({ label, children }) => {
  return (
    <Flex w="100%" justify="space-between">
      <Text>{label}</Text>
      <Text fontWeight="bold" textAlign="right">
        {children}
      </Text>
    </Flex>
  )
}

export default memo(SubSection)

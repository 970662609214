import { memo, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress, Flex, VStack } from '@chakra-ui/react'

import api from 'services/api'
import TableBackend from 'components/TableBackend'

import { cols } from './constants'
import { formatData } from '../actions'

interface IProps {
  row: { [key: string]: any }
  showSubRow: boolean
}

export const SubRow: React.FC<IProps> = ({ row, showSubRow }) => {
  const { id } = useParams()

  const [data, setData] = useState<any[]>([])

  const getData = useCallback(() => {
    const url = `/sceneries/${id}/items/${row.id}/clients`

    api.get(url).then((res) => {
      setData(formatData(res.data.data.clients))
    })
  }, [id, row.id])

  useEffect(() => {
    if (showSubRow) getData()
  }, [getData, showSubRow, row])

  if (!showSubRow) return null
  return (
    <VStack pb="1rem" w="100%" align="flex-start">
      <TableBackend
        cols={cols}
        limit={999}
        page={1}
        rows={data}
        setPage={() => {}}
        total={data.length}
        totalPages={1}
      />
    </VStack>
  )
}

export default memo(SubRow)

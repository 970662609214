import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

import api from '../../../../services/api'
import Button from 'components/Button'

import ArredondamentoInputs from './ArredondamentoInputs'

const ArredondamentoCreate: React.FC = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [end, setEnd] = useState('')
  const [start, setStart] = useState('')
  const [round, setRound] = useState('CEILING')
  const [example, setExample] = useState('')
  const [accepted, setAccepted] = useState<number[]>([])
  const [precision, setPrecision] = useState('')
  const [roundHouse, setRoundhouse] = useState<number>(0)

  const adaptPrecision = useCallback(() => {
    const zeroedEnd = end?.replace(/\d/g, '0')

    const adaptedPrecision = zeroedEnd
      .split('')
      .map((char, index) => {
        const revIndex = zeroedEnd.split('').length - index - 1
        return roundHouse === revIndex ? 'X' : char
      })
      .join('')

    return setPrecision(adaptedPrecision)
  }, [end, roundHouse])
  useEffect(adaptPrecision, [adaptPrecision])

  const onSave = useCallback(() => {
    if (!start) {
      return toast({
        title: 'Faixa inicial é obrigatória.',
        status: 'error',
        isClosable: true,
      })
    }

    if (!end) {
      return toast({
        title: 'Faixa final é obrigatória.',
        status: 'error',
        isClosable: true,
      })
    }

    if (+start > +end) {
      return toast({
        title: 'Faixa inicial deve ser menor que final.',
        status: 'error',
        isClosable: true,
      })
    }

    if (accepted.length === 0) {
      return toast({
        title: 'Rejeitar requer pelo menos 1 valor.',
        status: 'error',
        isClosable: true,
      })
    }

    const request = {
      to: +end,
      from: +start,
      example,
      ignores: _.times(9, (n) => n + 1)
        .filter((n) => !accepted.includes(n))
        .join(','),
      accepts: accepted.join(','),
      rounding_method: round,
      rounding_precision: precision,
    }

    api
      .post('/roundings', request)
      .then(() => {
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [accepted, end, example, precision, round, start, toast])

  return (
    <>
      <Button onClick={onOpen}>Adicionar</Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="50rem">
          <ModalHeader>Adicionar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ArredondamentoInputs
              end={end}
              setEnd={setEnd}
              start={start}
              setStart={setStart}
              round={round}
              setRound={setRound}
              setExample={setExample}
              accepted={accepted}
              setAccepted={setAccepted}
              roundHouse={roundHouse}
              setRoundhouse={setRoundhouse}
            />
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onSave}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ArredondamentoCreate

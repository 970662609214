import { HStack } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'

import Card from './Card'
import IData from './types/IData'

interface IProps {
  getBigData: boolean
}

const Cards: React.FC<IProps> = ({ getBigData }) => {
  const { id } = useParams()

  const [data, setData] = useState<IData[]>([])

  const getData = useCallback(() => {
    api.get(`/sceneries/${id}/big-numbers`).then((res) => {
      const info = res.data.data.big_numbers[0]

      setData([
        {
          title: 'Preço Médio Histórico',
          main: 1.99,
          volume: 330000.00,
          faturamento: 657900.00,
          margem1: 20550.00,
          margem2: 15.6,
          margem3: 102725.00,
          margem4: 31.2,
          color: 'green',
        },
        {
          title: 'Preço Médio Atual',
          main: 1.99,
          volume: 330000.00,
          faturamento: 657900.00,
          margem1: 20550.00,
          margem2: 15.6,
          margem3: 102725.00,
          margem4: 31.2,
          color: 'purple',
        },
        {
          title: 'Preço Médio Simulado',
          main: 1.99,
          volume: 330000.00,
          faturamento: 657900.00,
          margem1: 20550.00,
          margem2: 15.6,
          margem3: 102725.00,
          margem4: 31.2,
          indice: 95,
          color: 'yellow',
        },
        {
          title: 'Preço Médio Final',
          main: 1.99,
          volume: 330000.00,
          faturamento: 657900.00,
          margem1: 20550.00,
          margem2: 15.6,
          margem3: 102725.00,
          margem4: 31.2,
          indice: 95,
          color: 'blue',
        },
      ])
    })
  }, [id])
  useEffect(getData, [getData, getBigData])

  return (
    <HStack w="100%" spacing="1rem" mb="1rem">
      {data.map((info) => (
        <Card key={info.title} info={info} />
      ))}
    </HStack>
  )
}

export default Cards

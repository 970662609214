import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import { Flex, Text, VStack } from '@chakra-ui/react'
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js'

import { bgColors, defaultData, options } from './constants'
import { MOCK_MANUFACTURER_MARKET_SHARE_GRAPH } from '../MOCK_DATA'

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface IProps {
  productId: string
}

const ManufacturerMarketShare: React.FC<IProps> = ({ productId }) => {
  const [ready, setReady] = useState(false)
  const [graphData, setGraphData] = useState(defaultData)

  useEffect(() => {
    setReady(false)
  }, [productId])

  const getData = useCallback(() => {
    if (!productId) return

    const newData = { ...defaultData }
    const months = MOCK_MANUFACTURER_MARKET_SHARE_GRAPH

    newData.labels = months.map((month: any) => month.month)

    const manufacturers = months[0].manufacturers.map(
      (manufacturer: any) => manufacturer.name
    )

    newData.datasets = manufacturers.map(
      (manufacturer: string, index: number) => {
        return {
          label: manufacturer,
          borderWidth: 0,
          maxBarThickness: 60,
          backgroundColor: bgColors[index],
          data: months.map((month: any) => {
            return month.manufacturers.find((m: any) => m.name === manufacturer)
              ?.value
          }),
        }
      }
    )

    setGraphData(newData)
    setTimeout(() => setReady(true), 0)
  }, [productId])
  useEffect(getData, [getData])

  if (!productId || !ready) return null
  return (
    <VStack
      w="100%"
      h="40rem"
      p="1rem"
      align="flex-start"
      bgColor="#fff"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.200"
    >
      <Text fontWeight="semibold" fontSize="md">
        Market Share Por Fabricante
      </Text>
      <Flex w="100%" h="100%">
        <Bar options={options} data={graphData} />
      </Flex>
    </VStack>
  )
}

export default ManufacturerMarketShare

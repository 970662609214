export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: { label: (tooltipItem: any) => `${tooltipItem.raw}%` },
    },
    datalabels: {
      formatter: (value: any) => value + '%',
      color: 'black',
    },
  },
  scales: {
    y: {
      ticks: { callback: (val: any) => val + '%', maxTicksLimit: 5 },
    },
  },
}

export const defaultData = {
  labels: [''],
  datasets: [
    {
      data: [0],
      borderColor: [''],
      backgroundColor: [''],
      borderWidth: 0,
      maxBarThickness: 60,
    },
  ],
}

import { useCallback, useEffect, useState } from 'react'
import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'

import Contract from './Contract'
import ViewButton from './ViewButton'
import DeleteButton from './DeleteButton'
import CreateButton from './CreateButton'

interface IRow {
  id: number | string
  name: string
  factory: string
}

interface IProps {
  row: IRow
  isExpanded: boolean
}

const Panel: React.FC<IProps> = ({ row, isExpanded }) => {
  const [bundles, setBundles] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const [uf, setUf] = useState<IOption>({ label: '', value: '' })
  const [ufOpt, setUfOpt] = useState<IOption[]>([])
  const [channel, setChannel] = useState<IOption>({ label: '', value: '' })
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])

  useEffect(() => {
    setLoading(true)
  }, [uf, channel])

  const resetData = useCallback(() => {
    if (!isExpanded) {
      setLoading(true)
      setUfOpt([])
      setBundles([])
      setChannelOpt([])
      setUf({ label: '', value: '' })
      setChannel({ label: '', value: '' })
    }
  }, [isExpanded])
  useEffect(resetData, [resetData])

  const clearFilters = useCallback(() => {
    setUf({ label: '', value: '' })
    setChannel({ label: '', value: '' })
  }, [])

  const getBundles = useCallback(() => {
    const url = `/clusters/${row.id}/bundles`
    const req = { uf: uf.value, channel_id: channel.value }

    api.post(url, req).then((response) => {
      setBundles(response.data.data.bundles)
      setTimeout(() => setLoading(false), 1000)
    })
  }, [channel.value, row.id, uf.value])

  useEffect(() => {
    if (!isExpanded || !loading) return
    getBundles()
  }, [getBundles, isExpanded, loading])

  const getFilters = useCallback(() => {
    if (!isExpanded) return

    const url = `/clusters/${row.id}/bundles/filters`
    const req = { uf: uf.value }

    api.post(url, req).then((res) => {
      setChannel({ label: '', value: '' })
      setUfOpt(
        res.data.data.filters.ufs.map((v: any) => ({
          label: v.name,
          value: v.id,
        }))
      )
      setChannelOpt(
        res.data.data.filters.channels.map((v: any) => ({
          label: v.name,
          value: v.id,
        }))
      )
    })
  }, [isExpanded, row.id, uf.value])
  useEffect(getFilters, [getFilters])

  return (
    <>
      <HStack w="100%">
        <HStack w="100%" maxW="30rem">
          <VStack w="100%" align="flex-start">
            <Text>UF</Text>
            <Autocomplete value={uf} options={ufOpt} setValue={setUf} />
          </VStack>
          <VStack w="100%" align="flex-start">
            <Text>Canal</Text>
            <Autocomplete
              value={channel}
              options={channelOpt}
              setValue={setChannel}
            />
          </VStack>
        </HStack>
        {(uf.value || channel.value) && (
          <Button onClick={clearFilters} containerStyle={{ height: '3.1rem' }}>
            Limpar Filtros
          </Button>
        )}
      </HStack>
      <CreateButton row={row} getBundles={getBundles} />
      {loading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDir="column" maxH="20rem" overflowY="auto">
          <Grid
            w="100%"
            mb={2}
            px={1}
            gap={6}
            templateColumns="0.1fr 0.25fr 1fr 0.25fr 0.1fr"
          >
            <GridItem>UF</GridItem>
            <GridItem>Cidade</GridItem>
            <GridItem>Canal</GridItem>
            <GridItem></GridItem>
          </Grid>
          {bundles.map((bundle) => (
            <Grid
              key={bundle.id}
              w="100%"
              gap={6}
              p={1}
              color="#919191"
              templateColumns="0.1fr 0.25fr 1fr 0.25fr 0.1fr"
              borderTop="1px solid transparent"
              borderBottom="1px solid transparent"
              _hover={{
                color: '#003b74',
                bgColor: '#EDF2F7',
                borderTop: '1px solid #E2E8F0',
                borderBottom: '1px solid #E2E8F0',
              }}
            >
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle.uf_code}
                </Flex>
              </GridItem>
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle.city}
                </Flex>
              </GridItem>
              <GridItem>
                <Flex h="100%" align="center">
                  {bundle.channel_name}
                </Flex>
              </GridItem>
              <GridItem>
                <HStack h="100%" spacing={4} align="center">
                  <ViewButton bundle={bundle} />
                  <DeleteButton bundle={bundle} getBundles={getBundles} />
                </HStack>
              </GridItem>
            </Grid>
          ))}
        </Flex>
      )}
    </>
  )
}

export default Panel

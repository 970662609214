import Money from './Money'
import Percent from './Percent'
import Checkbox from './Checkbox'
import HeaderCheckbox from './HeaderCheckbox'

const styles = () => {
  const bgColor = (status: string) =>
    ({
      S: '#F0FFF4',
      N: '#FFF5F5',
    }[status] || '#FFF')

  return {
    td: ({ row }: any) => ({
      bgColor: bgColor(row.status),
      borderBottom: '1px solid #F2F3F5 !important',
    }),
  }
}

export const colsSimple = [
  {
    Header: ({
      type,
      filters,
      getRows,
      setRows,
      allChecked,
      setAllChecked,
    }: any) =>
      type === 'edit' && (
        <HeaderCheckbox
          filters={filters}
          getRows={getRows}
          setRows={setRows}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
        />
      ),
    accessor: ({ type, row, updateCell, setAllChecked }: any) =>
      type === 'edit' && (
        <Checkbox
          row={row}
          updateCell={updateCell}
          setAllChecked={setAllChecked}
        />
      ),
  },
  { Header: 'Cliente', accessor: 'client_name' },
  { Header: 'Cluster', accessor: 'name' },
  { Header: 'Fábrica', accessor: 'factory' },
  { Header: 'UF Destino', accessor: 'uf_destiny' },
  { Header: 'Canais', accessor: 'nome_canal' },
  { Header: 'Cód. Capitão', accessor: 'cod_capitan' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Classificação do Item', accessor: 'classificacao_produto' },
  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'PPV Atual',
    accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <Percent value={row.adicional_contrato} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_sugestao} />,
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.margem_objetiva} />,
  },
  {
    Header: 'Mrg PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.margem_ppv_novo} />,
  },
  {
    Header: 'Novo PPC',
    accessor: ({ row }: any) => <Money value={row.ppc} />,
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice} />,
  },
].map((col) => ({ ...col, styles: styles() }))

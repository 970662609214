import StylesProvider from 'providers/StylesProvider'
import RoutesProvider from 'providers/RoutesProvider'

const AppProvider: React.FC = () => {
  return (
    <StylesProvider>
      <RoutesProvider />
    </StylesProvider>
  )
}

export default AppProvider

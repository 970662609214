import styled from 'styled-components'
import { FaTrash as DeleteSvg } from 'react-icons/fa'

export const DeleteButton = styled(DeleteSvg)`
  cursor: pointer;

  color: #ac1010;
  font-size: 1.2rem;
  transition: all 100ms ease-in-out;

  :hover {
    color: red;
  }
`

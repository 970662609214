import React, { useCallback, useEffect, useState } from 'react'
import { TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { FaTrash as DeleteSvg } from 'react-icons/fa'
import { Box, Text } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'

import InputRelativity from './InputRelativity'
import { columns, addColumns } from './constants'
import { Table, DeleteContainer } from './styles'
import { Title } from 'components/PageTitle/styles'
import AddComponent from './AddComponent'

interface IProps {
  rows: any[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  captain: string
}

const Body: React.FC<IProps> = ({ rows, setRows, captain }) => {
  const [isAdding, setIsAdding] = useState(false)

  const [sku, setSku] = useState({ label: '', value: '' })
  const [skuOpt, setSkuOpt] = useState<any>([])

  const [error, setError] = useState('')

  const getSku = useCallback(() => {
    api.get('/materials').then((res) => {
      setSkuOpt(
        res.data.data.materials.map(
          (item: any) =>
            +item.sku && {
              label: `${+item.sku} - ${item.descricao_produto}`,
              value: +item.sku + '',
            }
        )
      )
    })
  }, [])
  useEffect(getSku, [getSku])

  const onDelete = useCallback(
    (skuToDel: any) => {
      api.delete(`/materials/${skuToDel}/captain`).then(() => {
        setRows((current: any) => {
          return current.filter((row: any) => row.sku !== skuToDel)
        })
      })
    },
    [setRows]
  )

  const onCancel = useCallback(() => {
    setSku({ label: '', value: '' })
    setError('')
    setIsAdding(false)
  }, [])
  useEffect(onCancel, [onCancel, rows])

  const onAdd = useCallback(() => {
    const url = `/captains/${captain}/materials`
    const req = { sku: +sku.value, relativity: 100 }
    api
      .post(url, req)
      .then((res) => {
        onCancel()
        setRows((current) => [
          ...current,
          {
            sku: res.data.data.captain.sku,
            description: res.data.data.captain.description,
            relativity: res.data.data.captain.relativity,
          },
        ])
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }, [sku, captain, onCancel, setRows])

  if (rows?.length === 0) return null

  return (
    <Paper containerStyle={{ padding: '2rem', overflow: 'visible' }}>
      <Box
        display={!isAdding ? 'flex' : 'block'}
        justifyContent={'space-between'}
        marginBottom={'15px'}
      >
        <Title>
          <Text fontSize={'16pt'}>Produtos filho</Text>
        </Title>
        <AddComponent
          isAdding={isAdding}
          setIsAdding={() => setIsAdding(true)}
          sku={sku}
          skuOpt={skuOpt}
          setSku={setSku}
          error={error}
          onCancel={onCancel}
          onAdd={onAdd}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.Header}>{column.Header}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                <InputRelativity row={row} captain={captain} />
              </TableCell>
              <TableCell>
                <DeleteContainer>
                  <DeleteSvg onClick={() => onDelete(row.sku)} />
                </DeleteContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Body
import styled from 'styled-components'

interface ContainerProps {
  borderRadius?: string
  label?: any
}

interface InputTextProps {
  borderRadius?: string
  isDisabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  border-radius: 10px;
  border: 1px solid #ddd7e5;
  width: 100%;
  height: 50px;
  margin-top: ${({ label }) => label && '32px'};

  display: flex;
  align-items: center;

  & + div {
    margin-top: 1rem;
  }

  svg {
    margin: 0 0.3rem 0 1.1rem;
    path {
      fill: rgba(0, 0, 0, 0.87);
    }
  }

  label {
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 1.3rem;
    color: #003b74;
  }
`

export const InputText = styled.input<InputTextProps>`
  flex: 1;
  border: 0;
  color: #324147;
  height: 100%;
  background: transparent;
  padding: 1.2rem;
  font-size: 1.3rem;
  border-radius: 10px;

  color: ${({ isDisabled }) => isDisabled && '#b1aabb'};

  &::placeholder {
    color: #b1aabb;
  }

  :focus {
    outline: 1px solid transparent;
  }
`

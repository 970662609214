import api from 'services/api'

import nloop from './nloop'

type THandleGetOptions = (
  id: string,
  req: { [n: string]: string[] },
  cb: (data: { [n: string]: IOption[] }) => void
) => void

export const handleGetOptions: THandleGetOptions = (id, req, cb) => {
  api.post(`/discount-scenery/${id}/items/filters`, req).then((res) => {
    console.log('auqi')

    const data = { ...res.data.data }

    data.cluster =
      data.cluster?.map((cluster: any) => ({
        label: cluster.name,
        value: cluster.id,
      })) || []

    data.channel =
      data.channel?.map((channel: any) => ({
        label: channel.name,
        value: channel.id,
      })) || []

    data.client =
      data.client_name?.map((client: string) => ({
        label: client,
        value: client,
      })) || []

    data.niveis = {}

    nloop.forEach((n) => {
      data.niveis[n] = data[`nivel0${n}`]?.map((nivel: string) => ({
        label: nivel,
        value: nivel,
      }))
    })

    console.log('data', data)

    cb(data)
  })
}

import { useCallback } from 'react'
import moment from 'moment'
import { Flex, HStack, Input, Text, VStack } from '@chakra-ui/react'

interface IProps {
  dateIni: string
  dateEnd: string
  setDateIni: React.Dispatch<React.SetStateAction<string>>
  setDateEnd: React.Dispatch<React.SetStateAction<string>>
}

const VolumePeriod: React.FC<IProps> = ({
  dateIni,
  dateEnd,
  setDateIni,
  setDateEnd,
}) => {
  const onChangeDateIni = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateIni

      setDateIni(e.target.value)

      if (dateEnd && moment(dateEnd).isBefore(e.target.value)) {
        return setDateIni(current)
      }
    },
    [dateEnd, dateIni, setDateIni]
  )

  const onChangeDateEnd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateEnd
      setDateEnd(e.target.value)

      if (dateIni && moment(e.target.value).isBefore(dateIni)) {
        return setDateEnd(current)
      }
    },
    [dateEnd, setDateEnd, dateIni]
  )

  return (
    <Flex w="100%" flexDir="column">
      <HStack w="100%" spacing="1rem">
        <VStack w="100%" align="flex-start">
          <Text>Período Início</Text>
          <Input
            w="100%"
            type="date"
            value={dateIni}
            onChange={onChangeDateIni}
            onKeyDown={(e) => e.preventDefault()}
          />
        </VStack>
        <VStack w="100%" align="flex-start">
          <Text>Período Fim</Text>
          <Input
            w="100%"
            type="date"
            value={dateEnd}
            onChange={onChangeDateEnd}
            onKeyDown={(e) => e.preventDefault()}
          />
        </VStack>
      </HStack>
    </Flex>
  )
}

export default VolumePeriod

import { Flex, AccordionIcon, AccordionButton, Text } from '@chakra-ui/react'

import Panel from './Panel'
import DeleteButton from './DeleteButton'
import { Container, Main } from './styles'

import IRounding from '../types/IRounding'

interface IProps {
  row: IRounding
}

const Row: React.FC<IProps> = ({ row }) => {
  return (
    <Container>
      {({ isExpanded }: any) => (
        <>
          <Main>
            <AccordionButton _expanded={{ bg: '#003b74', color: 'white' }}>
              <Flex flex="1" textAlign="left">
                <Text w="10rem">
                  {row.from.toFixed(2)} até {row.to.toFixed(2)}
                </Text>
                <Text w="10rem">
                  {row.rounding_precision.split('').map((char, index) => (
                    <Text
                      key={`${index}-${`char`}`}
                      as="span"
                      color={
                        char === 'X'
                          ? 'red.500'
                          : isExpanded
                          ? 'white'
                          : '#003b74'
                      }
                    >
                      {char}
                    </Text>
                  ))}
                </Text>
                <Text>{row.example}</Text>
              </Flex>
              <DeleteButton row={row} />
              <AccordionIcon />
            </AccordionButton>
          </Main>
          <Panel row={row} isExpanded={isExpanded} />
        </>
      )}
    </Container>
  )
}

export default Row

import { useState } from 'react'

import Body from './Body'
import Header from './Header'

const RelatividadeDeProduto: React.FC<any> = () => {
  const [rows, setRows] = useState<any[]>([])
  const [captain, setCaptain] = useState('')

  return (
    <>
      <Header setRows={setRows} setCaptain={setCaptain} />
      <Body rows={rows} setRows={setRows} captain={captain} />
    </>
  )
}

export default RelatividadeDeProduto

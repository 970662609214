import { useState, useCallback } from 'react'

import ReactTable from './components/ReactTable'

import { Container, TablePagination } from './styles'

const TableBig = ({
  data = [],
  columns = [],
  setData = (current) => {},
  rowsPerPage = 20,
}) => {
  const [count, setCount] = useState(data.length)
  const [currentPage, setCurrentPage] = useState(0)

  const updateTableData = useCallback(
    (rowIndex, columnId, value) => {
      setData((current) =>
        current.map((row, index) =>
          index === rowIndex ? { ...row, [columnId]: value } : row
        )
      )
    },
    [setData]
  )

  const paginationOnChange = useCallback((_, newPageIndex) => {
    setCurrentPage(newPageIndex)
  }, [])

  const paginationFooterLabel = useCallback(({ from, to, count }) => {
    return `Visualizando ${from} - ${to} de ${count}`
  }, [])

  return (
    <Container>
      <ReactTable
        data={data}
        columns={columns}
        setCount={setCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        updateTableData={updateTableData}
      />
      <TablePagination
        component="div"
        page={currentPage}
        count={count}
        rowsPerPage={rowsPerPage}
        onPageChange={paginationOnChange}
        rowsPerPageOptions={false}
        labelDisplayedRows={paginationFooterLabel}
      />
    </Container>
  )
}

export default TableBig

import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HStack, useToast } from '@chakra-ui/react'
import { FaCheckDouble as ApprovalSvg } from 'react-icons/fa'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import ExportExcelButton from 'components/ExportExcelButton'

import IFilters from '../types/IFilters'
import FilterButton from './FilterButton'
import ConfirmButton from './ConfirmButton'
import { Container } from './styles'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
}

const Header: React.FC<IProps> = ({ filters, setFilters }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()

  const onSave = useCallback(() => {
    if (type === 'edit') {
      toast({
        title: 'Salvo com sucesso!',
        status: 'success',
      })
    }

    navigate('/approval')
  }, [type, toast, navigate])

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle icon={<ApprovalSvg />} title="Requisições" />
      </HStack>
      <HStack spacing="1rem">
        <FilterButton filters={filters} setFilters={setFilters} />
        <ExportExcelButton url={`/requests/${id}/export`} />
        <Button onClick={onSave}>
          {type === 'edit' ? 'Salvar' : 'Voltar'}
        </Button>
        {type === 'edit' && <ConfirmButton />}
      </HStack>
    </Container>
  )
}

export default Header

import { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: any
  clusterId: any
}

const InputRelativity: React.FC<IProps> = ({ clusterId, row }) => {
  const [relativity, setRelativity] = useState(row.relativity)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRelativity(e.target.value)
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const newVal = e.target.value || '0'
      setRelativity(newVal)

      const url = `/relativity-channels/${clusterId}/${row.id}/relativity`
      const req = {
        captain_channel_id: row.captain_channel_id,
        relativity: +newVal,
      }

      api.put(url, req).catch(() => {
        setRelativity(row.relativity)
      })
    },
    [clusterId, row.captain_channel_id, row.id, row.relativity]
  )

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        maxW="7rem"
        type="number"
        name="relativity"
        value={relativity}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={6}
      />
    </InputGroup>
  )
}

export default InputRelativity

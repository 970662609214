import { Flex } from '@chakra-ui/react'
import { useMemo } from 'react'

interface IProps {
  status: string
}

const Status: React.FC<IProps> = ({ status }) => {
  const color = useMemo(() => {
    if (/aguardando|editando/i.test(status)) return 'yellow'
    if (/aprovado|enviado/i.test(status)) return 'green'
    if (/reprovado/i.test(status)) return 'red'
    return 'gray'
  }, [status])

  return (
    <Flex
      color={`${color}.700`}
      bgColor={`${color}.100`}
      py="0.5rem"
      maxW="15rem"
      justify="center"
      textAlign="center"
      lineHeight="1"
      fontWeight="md"
      borderRadius="xl"
    >
      {status}
    </Flex>
  )
}

export default Status

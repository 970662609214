import { Fragment } from 'react'
import { Flex, Grid, Text } from '@chakra-ui/react'

interface IProps {
  data?: { [key: string]: any }
}

const cols = [
  { Header: 'Qt Média', accessor: 'qtd_media' },
  { Header: '% Dia Tipo', accessor: 'dia_tipo' },
]

const SeasonalityWeek: React.FC<IProps> = ({ data }) => {
  if (!data) return null
  return (
    <Flex
      w="100%"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bgColor="#f5f6f8"
      overflow="hidden"
    >
      <Text fontWeight="semibold" fontSize="sm" p="1rem" bgColor="#fff">
        Performance do Produto - Dia da Semana
      </Text>
      <Grid
        w="100%"
        p="1rem"
        gap="0.25rem"
        templateColumns="1.15fr repeat(7, 1fr)"
      >
        <Flex w="100%">
          <Text></Text>
        </Flex>
        {data.weeks.map((day: string) => (
          <Flex w="100%" key={day}>
            <Text fontWeight="semibold">{day}</Text>
          </Flex>
        ))}
        {cols.map((col) => (
          <Fragment key={col.Header}>
            <Flex w="100%">
              <Text fontWeight="semibold">{col.Header}</Text>
            </Flex>
            {data.weeks.map((day: string) => (
              <Flex w="100%" key={day}>
                <Text>{data[col.accessor][day]}</Text>
              </Flex>
            ))}
          </Fragment>
        ))}
      </Grid>
    </Flex>
  )
}

export default SeasonalityWeek

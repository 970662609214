import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import api from 'services/api'
import Paper from 'components/Paper'
import Status from 'components/Status'
import TableBackend from 'components/TableBackend'

import { ViewSvg } from './styles'

const limit = 10

const Table: React.FC = () => {
  const navigate = useNavigate()

  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const onView = useCallback(
    (id: string) => {
      navigate(`/approval/${id}`)
    },
    [navigate]
  )

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Solicitante', accessor: 'user' },
      { Header: 'Nome', accessor: 'name' },
      {
        Header: 'Data',
        accessor: ({ row }: any) => (
          <>{moment(row.created_at).zone(0).format('DD/MM/YYYY HH:mm')}</>
        ),
      },
      {
        Header: 'Status',
        accessor: ({ row }: any) => <Status status={row.status} />,
      },
      {
        Header: 'Visualizar',
        accessor: ({ row }: any) =>
          /aguardando/i.test(row.status) && (
            <ViewSvg onClick={() => onView(row.id)} />
          ),
      },
    ]
  }, [onView])

  const getUsers = useCallback(() => {
    api.get(`/requests/all?page=${page}&limit=${limit}`).then((res) => {
      setRows(
        res.data.data.requests.map((row: any) => ({
          ...row,
          user: row.creator.name,
        }))
      )
      setTotal(res.data.data.total)
      setTotalPage(res.data.data.totalPages)
      setLoading(false)
    })
  }, [page])

  useEffect(getUsers, [getUsers])

  // if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%', margin: 'auto 0' }}>
      <TableBackend
        rows={rows}
        cols={columns}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
      />
    </Paper>
  )
}

export default Table

import { useCallback } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'

interface IProps {
  accepted: number[]
  setAccepted: React.Dispatch<React.SetStateAction<number[]>>
}

const values = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const Accepted: React.FC<IProps> = ({ accepted, setAccepted }) => {
  const checked = useCallback(
    (value: number) => {
      return accepted.includes(value)
    },
    [accepted]
  )

  const onCheck = useCallback(
    (value: number) => {
      setAccepted((current) =>
        current.includes(value)
          ? current.filter((i) => i !== value)
          : [...current, value]
      )
    },
    [setAccepted]
  )

  return (
    <VStack align="flex-start">
      <Text>Aceitar</Text>
      <HStack align="flex-start">
        {values.map((value, index) => (
          <Text
            key={`${value}${index}`}
            cursor="pointer"
            fontSize="md"
            color={checked(value) ? 'red.500' : 'primary.500'}
            onClick={() => onCheck(value)}
          >
            {value}
          </Text>
        ))}
      </HStack>
    </VStack>
  )
}

export default Accepted

import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: any
  checked: any
  clusterId: any
  setChecked: any
  findCaptain: any
}

const Checkbox: React.FC<IProps> = ({
  row,
  checked,
  clusterId,
  setChecked,
  findCaptain,
}) => {
  const toast = useToast()

  const onCheck = useCallback(() => {
    setChecked(row.id)
    api
      .put(`/relativity-channels/${clusterId}/captain`, { channel_id: row.id })
      .catch(() => {
        findCaptain()
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [clusterId, findCaptain, row.id, setChecked, toast])

  return <input type="radio" checked={checked === row.id} onChange={onCheck} />
}

export default Checkbox

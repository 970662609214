import { useCallback, useEffect, useState } from 'react'
import { Accordion, Flex, Text } from '@chakra-ui/react'

import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'

import Row from './Row'
import IRounding from './types/IRounding'

const Arredondamento: React.FC = () => {
  const [rows, setRows] = useState<IRounding[]>([])
  const [loading, setLoading] = useState(true)

  const getRows = useCallback(() => {
    api.get('/roundings').then((response) => {
      setRows(response.data.data.roundings)
      setLoading(false)
    })
  }, [])

  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Flex
        flex="1"
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#f2f3f5"
        borderRadius="4px"
      >
        <Text w="10rem">Range</Text>
        <Text w="10rem">Arredondamento</Text>
        <Text w="10rem">Exemplo</Text>
      </Flex>
      <Accordion allowToggle borderBottom="1px solid transparent">
        {rows.map((row, index) => (
          <Row key={`${index}-${row.from}-${row.to}`} row={row} />
        ))}
      </Accordion>
    </Paper>
  )
}

export default Arredondamento

import { Container, Background } from './styles'

import backgroundImg from 'assets/prixsia-background.png'

import Form from './Form'
import Footer from './Footer'

const Login: React.FC = () => {
  return (
    <Container>
      <Background src={backgroundImg} alt="background" />
      <Form />
      <Footer />
    </Container>
  )
}

export default Login

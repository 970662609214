import { useCallback } from 'react'
import moment from 'moment'
import { Flex, HStack, Input, Text, useToast, VStack } from '@chakra-ui/react'

interface IProps {
  dateIni: string
  dateEnd: string
  setDateIni: React.Dispatch<React.SetStateAction<string>>
  setDateEnd: React.Dispatch<React.SetStateAction<string>>
}

const VolumePeriod: React.FC<IProps> = ({
  dateIni,
  dateEnd,
  setDateIni,
  setDateEnd,
}) => {
  const toast = useToast()

  const onChangeDateIni = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateIni
      setDateIni(e.target.value)

      if (
        moment(e.target.value).isAfter(moment(new Date()).add('-2', 'days'))
      ) {
        toast({
          status: 'error',
          title: 'Data início máxima é D-2.',
        })
        return setDateIni(current)
      }

      if (dateEnd && moment(dateEnd).isBefore(e.target.value)) {
        toast({
          status: 'error',
          title: 'Data início não pode ser após data fim.',
        })
        return setDateIni(current)
      }
    },
    [dateEnd, dateIni, setDateIni, toast]
  )

  const onChangeDateEnd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateEnd
      setDateEnd(e.target.value)

      if (
        moment(e.target.value).isAfter(moment(new Date()).add('-1', 'days'))
      ) {
        toast({
          status: 'error',
          title: 'Data fim máxima é D-1.',
        })
        return setDateEnd(current)
      }

      if (dateIni && moment(e.target.value).isBefore(dateIni)) {
        toast({
          status: 'error',
          title: 'Data início não pode ser após data fim.',
        })
        return setDateEnd(current)
      }
    },
    [dateEnd, setDateEnd, dateIni, toast]
  )

  return (
    <Flex w="100%" flexDir="column">
      <Text>Período Para Volume</Text>
      <HStack w="100%" spacing="1rem">
        <VStack w="100%" align="flex-start">
          <Text>Período Início</Text>
          <Input
            w="100%"
            type="date"
            value={dateIni}
            onChange={onChangeDateIni}
            onKeyDown={(e) => e.preventDefault()}
          />
        </VStack>
        <VStack w="100%" align="flex-start">
          <Text>Período Fim</Text>
          <Input
            w="100%"
            type="date"
            value={dateEnd}
            onChange={onChangeDateEnd}
            onKeyDown={(e) => e.preventDefault()}
          />
        </VStack>
      </HStack>
    </Flex>
  )
}

export default VolumePeriod

import { Text } from '@chakra-ui/react'

import Autocomplete from 'components/Autocomplete'

interface IProps {
  label: string
  value: IOption[]
  options: IOption[]
  setValue: React.Dispatch<React.SetStateAction<IOption[]>>
}

const FilterAutoComplete: React.FC<IProps> = ({
  label,
  value,
  options,
  setValue,
}) => {
  return (
    <>
      <Text>{label}</Text>
      <Autocomplete
        value={value}
        options={options}
        setValue={setValue}
        isMulti
      />
    </>
  )
}

export default FilterAutoComplete

import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Checkbox as ChakraCheckbox, HStack } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  filters: { [key: string]: any }
  getRows: () => void
  setRows: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>
  allChecked: string
  setAllChecked: React.Dispatch<React.SetStateAction<string>>
}

const CustomIcon = ({ isChecked }: any) => {
  return <>{isChecked ? 'X' : null}</>
}

export const HeaderCheckbox: React.FC<IProps> = ({
  filters,
  getRows,
  setRows,
  allChecked,
  setAllChecked,
}) => {
  const { id } = useParams()

  const onChange = useCallback(
    (newVal: string) => {
      setAllChecked(newVal)
      setRows((current) => current.map((row) => ({ ...row, status: newVal })))

      const url = `/requests/${id}/items/status`
      const req: { [key: string]: any } = { status: newVal }

      Object.keys(filters).forEach((key) => {
        req[key] = filters[key]?.map((v: IOption) => v.value) || []
      })

      api.post(url, req).then(() => {
        // getRows()
        // setLoading(true)
      })
    },
    [filters, id, setAllChecked, setRows]
  )

  return (
    <HStack>
      <ChakraCheckbox
        size="lg"
        colorScheme="green"
        isChecked={allChecked === 'S'}
        onChange={() => onChange(allChecked === 'S' ? '-' : 'S')}
      />
      <ChakraCheckbox
        size="lg"
        colorScheme="red"
        icon={<CustomIcon />}
        isChecked={allChecked === 'N'}
        onChange={() => onChange(allChecked === 'N' ? '-' : 'N')}
      />
    </HStack>
  )
}

export default HeaderCheckbox

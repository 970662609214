import { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'
import { moneyComma } from 'utils/masks'

import { formatData } from '../actions'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  updateRow: (newRow: { [key: string]: any }) => void
  ppvInputsRef: React.MutableRefObject<any[]>
  toggleBigData: () => void
}

export const PPVFinal: React.FC<IProps> = ({
  row,
  rowIndex,
  updateRow,
  ppvInputsRef,
  toggleBigData,
}) => {
  const [value, setValue] = useState(row.ppv_novo)
  const [focusValue, setFocusValue] = useState(row.ppv_novo)

  const onBlur = useCallback(() => {
    if (!value) return setValue(focusValue)
    if (value === row.ppv_novo) return

    let enabled = true
    if (value === row.preco_liquido_unitario) enabled = false

    const url = '/discount-scenery/item'
    const req = { id: row.id, ppv_novo: +value?.replace(',', '.'), enabled }

    api.put(url, req).then((res) => {
      const formattedRow: any = formatData([{ ...res.data.data.scenery_item }])
      updateRow({ ...row, ...formattedRow[0], ppv_novo: value, enabled })
      toggleBigData()
    })
  }, [focusValue, row, toggleBigData, updateRow, value])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(moneyComma(e.target.value))
  }, [])

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') ppvInputsRef.current[rowIndex + 1].focus()
    },
    [rowIndex, ppvInputsRef]
  )

  return (
    <InputGroup w="7.75rem" size="sm">
      <InputLeftAddon fontSize="1rem !important">R$</InputLeftAddon>
      <Input
        ref={(el) => (ppvInputsRef.current[rowIndex] = el)}
        value={value}
        placeholder={focusValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        textAlign="right"
      />
    </InputGroup>
  )
}

export default PPVFinal

import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;

  overflow: hidden;
  background-color: #f5f9fe;
`

export const Main = styled.main`
  width: 100%;
  margin: 5.3rem 1rem 0;
  padding: 1rem 0 1rem;
  padding-right: 1rem;
  overflow-y: scroll;
`

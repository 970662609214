import { Flex } from '@chakra-ui/react'

import Cluster from './Cluster'
import Filters from './Filters'
import ButtonNivel from './ButtonNivel'

interface IProps {
  level: string
  parameter: string
  clusterId: string
  setRequest: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  setClusterId: React.Dispatch<React.SetStateAction<string>>
}

const Options: React.FC<IProps> = ({
  level,
  parameter,
  setRequest,
  clusterId,
  setClusterId,
}) => {
  return (
    <Flex flexDir="column" w="100%" maxW="15rem" pl="1px">
      <Cluster setClusterId={setClusterId} />
      {level && clusterId && (
        <>
          {/* <ButtonNivel
            level={level}
            clusterId={clusterId}
            parameter={parameter}
          /> */}
          <Filters level={level} setRequest={setRequest} />
        </>
      )}
    </Flex>
  )
}

export default Options

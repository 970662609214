import { useCallback, useState } from 'react'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Flex,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Modal from 'components/Modal'
import Button from 'components/Button'
import LoadingModalButton from 'components/LoadingModalButton'

const SendToApproveButton = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [dateIni, setDateIni] = useState('')
  const [dateEnd, setDateEnd] = useState('')

  const onChangeDateIni = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateIni

      setDateIni(e.target.value)

      if (dateEnd && moment(dateEnd).isBefore(e.target.value)) {
        return setDateIni(current)
      }
    },
    [dateEnd, dateIni, setDateIni]
  )

  const onChangeDateEnd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = dateEnd
      setDateEnd(e.target.value)

      if (dateIni && moment(e.target.value).isBefore(dateIni)) {
        return setDateEnd(current)
      }
    },
    [dateEnd, setDateEnd, dateIni]
  )

  const onConfirm = useCallback(() => {
    const url = '/sceneries/save'
    const req = { id: +id!, type, date_initial: dateIni, date_final: dateEnd }

    return api
      .post(url, req)
      .then(() => {
        navigate('/pricebase')
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro ao enviar, tente novamente.',
        })
      })
  }, [dateEnd, dateIni, id, navigate, toast, type])

  return (
    <>
      <Button onClick={onOpen}>Enviar Para Aprovação</Button>
      <Modal
        w="20rem"
        isOpen={isOpen}
        onClose={onClose}
        title="Enviar Para Aprovação"
        body={
          <VStack align="flex-start">
            <Text>Data Início</Text>
            <Input
              w="100%"
              type="date"
              value={dateIni}
              onChange={onChangeDateIni}
              onKeyDown={(e) => e.preventDefault()}
            />
            <Text>Data Fim</Text>
            <Input
              w="100%"
              type="date"
              value={dateEnd}
              onChange={onChangeDateEnd}
              onKeyDown={(e) => e.preventDefault()}
            />
          </VStack>
        }
        footer={
          <Flex w="100%" justify="space-between">
            <Button onClick={onClose}>Cancelar</Button>
            <LoadingModalButton
              disable={!dateIni || !dateEnd}
              promise={onConfirm}
              message="Enviando, por favor aguarde..."
            >
              Confirmar
            </LoadingModalButton>
          </Flex>
        }
      />
    </>
  )
}

export default SendToApproveButton

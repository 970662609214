import scenValue from 'utils/scenValue'

const _noValueValidation = (value: any, cases = 2) => {
  return !+value ? '-' : scenValue(value, cases)
}

export const formatData = (rawRows: Record<string, unknown>[]) => {
  return rawRows.map((row: any) => ({
    ...row,
    // Text only
    ppc: _noValueValidation(row.ppc),
    ppv: _noValueValidation(row.ppv),
    guia: _noValueValidation(row.guia),
    fator: _noValueValidation(row.fator),
    frete: _noValueValidation(row.frete),
    outros: _noValueValidation(row.outros),
    ipi_rs: _noValueValidation(row.ipi_rs),
    icms_rs: _noValueValidation(row.icms_rs),
    icms_st: _noValueValidation(row.icms_st),
    contrato: _noValueValidation(row.contrato),
    icms_st_p: _noValueValidation(row.icms_st_p),
    icms_rs_2: _noValueValidation(row.icms_rs_2),
    ppv_atual: _noValueValidation(row.ppv_atual),
    ppc_index: _noValueValidation(row.ppc_index),
    preco_base: _noValueValidation(row.preco_base),
    faturamento: _noValueValidation(row.faturamento),
    ppv_liquido: _noValueValidation(row.ppv_liquido),
    mrg_min_max: _noValueValidation(row.mrg_min_max),
    ppc_inicial: _noValueValidation(row.ppc_inicial),
    ppc_partida: _noValueValidation(row.ppc_partida),
    base_calc_st: _noValueValidation(row.base_calc_st),
    investimento: _noValueValidation(row.investimento),
    ppv_bruto_nf: _noValueValidation(row.ppv_bruto_nf),
    ppc_relativo: _noValueValidation(row.ppc_relativo),
    icms_por_fora: _noValueValidation(row.icms_por_fora),
    margem_minima: _noValueValidation(row.margem_minima),
    margem_maxima: _noValueValidation(row.margem_maxima),
    pis_cofins_rs: _noValueValidation(row.pis_cofins_rs),
    preco_mercado: _noValueValidation(row.preco_mercado),
    preco_min_max: _noValueValidation(row.preco_min_max),
    preco_sem_mrg: _noValueValidation(row.preco_sem_mrg),
    total_imposto: _noValueValidation(row.total_imposto),
    margem_ppv_novo: _noValueValidation(row.margem_ppv_novo),
    pis_cofins_rs_2: _noValueValidation(row.pis_cofins_rs_2),
    ppc_promocional: _noValueValidation(row.ppc_promocional),
    ppc_s_mg_varejo: _noValueValidation(row.ppc_s_mg_varejo),
    custo_medio_prod: _noValueValidation(row.custo_medio_prod),
    margem_min_valor: _noValueValidation(row.margem_min_valor),
    margem_max_valor: _noValueValidation(row.margem_max_valor),
    preco_pricepoint: _noValueValidation(row.preco_pricepoint),
    total_impostos_2: _noValueValidation(row.total_impostos_2),
    totais_impostos_1: _noValueValidation(row.totais_impostos_1),
    impostos_por_fora: _noValueValidation(row.impostos_por_fora),
    preco_ppc_regular: _noValueValidation(row.preco_ppc_regular),
    novo_ppc_sem_margem: _noValueValidation(row.novo_ppc_sem_margem),
    custo_medio_produto: _noValueValidation(row.custo_medio_produto),
    preco_ppc_sem_margem: _noValueValidation(row.preco_ppc_sem_margem),
    preco_relativo_canal: _noValueValidation(row.preco_relativo_canal),
    preco_ppc_promocional: _noValueValidation(row.preco_ppc_promocional),
    preco_liquido_unitario: _noValueValidation(row.preco_liquido_unitario),
    margem_contribuicao_nova: _noValueValidation(row.margem_contribuicao_nova),
    // Text + value fix
    ipi: _noValueValidation(row.ipi * 100),
    indice: _noValueValidation(row.indice * 100),
    indice_meta: _noValueValidation(row.indice_meta * 100),
    ipi_percent: _noValueValidation(row.ipi_percent * 100),
    mva_percent: _noValueValidation(row.mva_percent * 100),
    icms_destino: _noValueValidation(row.icms_destino * 100),
    margem_atual: _noValueValidation(row.margem_atual * 100, 0),
    margem_regular: _noValueValidation(row.margem_regular * 100),
    icms_st_percent: _noValueValidation(row.icms_st_percent * 100),
    margem_objetiva: _noValueValidation(row.margem_objetiva * 100),
    relatividade_canal: _noValueValidation(row.relatividade_canal * 100),
    pis_cofins_destino: _noValueValidation(row.pis_cofins_destino * 100),
    pis_cofins_percent: _noValueValidation(row.pis_cofins_percent * 100),
    media_margem_varejo: _noValueValidation(row.media_margem_varejo * 100),
    percentual_desconto: _noValueValidation(row.percentual_desconto * 100),
    // Inputs
    desconto: scenValue(row.desconto * 100),
    ppv_novo: scenValue(row.ppv_novo),
    ppv_final: scenValue(row.ppv_final),
    ppv_sugestao: scenValue(row.ppv_sugestao),
  }))
}

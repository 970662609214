import { useCallback, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModal from 'components/LoadingModal'

import PriceType from './PriceType'
import VolumePeriod from './VolumePeriod'

const ButtonRequestApproval: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loadingRef = useRef<any>(null)
  const [type, setType] = useState('TABELA')
  const [dateIni, setDateIni] = useState('')
  const [dateEnd, setDateEnd] = useState('')

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const createScenery = useCallback(() => {
    if (!dateIni || !dateEnd) {
      return toast({
        status: 'error',
        title: 'Todos os campos são obrigatórios.',
      })
    }
    navigate('/approval')
  }, [dateEnd, dateIni, navigate, toast])

  return (
    <>
      <Button onClick={onOpen}>Solicitar Aprovação</Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30rem">
          <ModalHeader>Solicitar Aprovação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="100%" align="flex-start" spacing="1rem">
              <PriceType type={type} setType={setType} />
              <VolumePeriod
                dateIni={dateIni}
                dateEnd={dateEnd}
                setDateIni={setDateIni}
                setDateEnd={setDateEnd}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button width="100%" onClick={createScenery}>
              Solicitar
            </Button>
            <LoadingModal ref={loadingRef} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ButtonRequestApproval

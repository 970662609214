import { useCallback, useMemo, useState } from 'react'
import { Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'
import { moneyComma } from 'utils/masks'

import Indicator from './Indicator'
import { formatData } from '../actions'

interface IProps {
  row: { [key: string]: any }
  rowIndex: number
  inputsRef: React.MutableRefObject<any[]>
  updateRow: (newRow: { [key: string]: any }) => void
  toggleBigData: () => void
}

export const PPVFinal: React.FC<IProps> = ({
  row,
  rowIndex,
  inputsRef,
  updateRow,
  toggleBigData,
}) => {
  const [value, setValue] = useState(row.ppv_sugestao)
  const [focusValue, setFocusValue] = useState(row.ppv_sugestao)

  const borderColor = useMemo(() => {
    return `${
      row.ppv_sugestao !== row.preco_liquido_unitario ? '#BEE3F8' : '#E2E8F0'
    } !important`
  }, [row.preco_liquido_unitario, row.ppv_sugestao])

  const onBlur = useCallback(() => {
    if (!value) return setValue(focusValue)
    if (value === row.ppv_sugestao) return

    let enabled = true
    if (value === row.preco_liquido_unitario) enabled = false

    const url = '/sceneries/item'
    const req = { id: row.id, ppv_sugestao: +value?.replace(',', '.'), enabled }

    api.put(url, req).then((res) => {
      const formattedRow: any = formatData([{ ...res.data.data.scenery_item }])
      updateRow({ ...row, ...formattedRow[0], ppv_sugestao: value, enabled })
      toggleBigData()
    })
  }, [focusValue, row, toggleBigData, updateRow, value])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(moneyComma(e.target.value))
  }, [])

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') inputsRef.current[rowIndex + 1].focus()
    },
    [rowIndex, inputsRef]
  )

  return (
    <Flex align="center" pos="relative">
      <InputGroup w="7.75rem" size="sm" borderColor={borderColor}>
        <InputLeftAddon bgColor={borderColor} fontSize="1rem !important">
          R$
        </InputLeftAddon>
        <Input
          ref={(el) => (inputsRef.current[rowIndex] = el)}
          value={value}
          placeholder={focusValue}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
          borderColor={borderColor}
          textAlign="right"
        />
      </InputGroup>
      <Indicator row={row} />
    </Flex>
  )
}

export default PPVFinal

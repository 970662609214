import Money from './Money'
import Percent from './Percent'
import Checkbox from './Checkbox'
import PPVFinal from './PPVFinal'
import Expander from './Expander'
import { Flex, HStack, Text } from '@chakra-ui/react'
import HeaderCheckbox from './HeaderCheckbox'

const hues = [210, 30, 250, 135]
const styles = (hue: number) => ({
  th: () => ({ bgColor: `hsla(${hue}, 30%, 91%, 1)` }),
  td: ({ rowIndex }: any) => ({
    bgColor:
      rowIndex & 1 ? `hsla(${hue}, 20%, 95%, 1)` : `hsla(${hue}, 25%, 98%, 1)`,
  }),
})

export const colsSimple = [
  {
    Header: ({ filters, getRows, allChecked, setAllChecked }: any) => (
      <HeaderCheckbox
        filters={filters}
        getRows={getRows}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
    ),
    accessor: ({
      row,
      updateCell,
      showSubRow,
      toggleSubRow,
      toggleBigData,
    }: any) => (
      <HStack>
        <Expander showSubRow={showSubRow} toggleSubRow={toggleSubRow} />
        <Checkbox
          row={row}
          updateCell={updateCell}
          toggleBigData={toggleBigData}
        />
      </HStack>
    ),
  },
  { Header: 'Cluster', accessor: 'name' },
  { Header: 'Fábrica', accessor: 'factory' },
  { Header: 'UF Destino', accessor: 'uf_destiny' },
  { Header: 'Canais', accessor: 'nome_canal' },
  { Header: 'Cód. Capitão', accessor: 'cod_capitan' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Classificação do Item', accessor: 'classificacao_produto' },
  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'PPV Atual',
    accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row, rowIndex, inputsRef, updateRow, toggleBigData }: any) => (
      <PPVFinal
        row={row}
        rowIndex={rowIndex}
        inputsRef={inputsRef}
        updateRow={updateRow}
        toggleBigData={toggleBigData}
      />
    ),
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.margem_objetiva} />,
  },
  {
    Header: 'Mrg PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.margem_ppv_novo} />,
  },
  {
    Header: 'Novo PPC',
    accessor: ({ row }: any) => <Money value={row.ppc} />,
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice} />,
  },
]

export const colsDetailed = [
  {
    Header: '',
    accessor: ({
      row,
      updateCell,
      showSubRow,
      toggleSubRow,
      toggleBigData,
    }: any) => (
      <HStack>
        <Expander showSubRow={showSubRow} toggleSubRow={toggleSubRow} />
        <Checkbox
          row={row}
          updateCell={updateCell}
          toggleBigData={toggleBigData}
        />
      </HStack>
    ),
  },
  { Header: 'Cluster', accessor: 'name' },
  { Header: 'Fábrica', accessor: 'factory' },
  { Header: 'UF Destino', accessor: 'uf_destiny' },
  { Header: 'Canais', accessor: 'nome_canal' },
  { Header: 'Cód. Capitão', accessor: 'cod_capitan' },
  { Header: 'SKU', accessor: 'sku' },
  { Header: 'Descrição', accessor: 'descricao_produto' },
  { Header: 'Nível 1', accessor: 'nivel01' },
  { Header: 'Nível 2', accessor: 'nivel02' },
  { Header: 'Nível 3', accessor: 'nivel03' },
  { Header: 'Nível 4', accessor: 'nivel04' },
  { Header: 'Nível 5', accessor: 'nivel05' },
  { Header: 'Classificação do Item', accessor: 'classificacao_produto' },
  // 1
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>PPC Base</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 1 - Preço Mercado e Índice
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.ppc_inicial} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Índice Meta',
    accessor: ({ row }: any) => <Percent value={row.indice_meta} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC Index',
    accessor: ({ row }: any) => <Money value={row.ppc_index} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Relatividade SKU',
    accessor: ({ row }: any) => <Percent value={row.relatividade_sku} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC Relativ.',
    accessor: ({ row }: any) => <Money value={row.ppc_relativo} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'Mrg Mercado Pond.',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
    styles: styles(hues[0]),
  },
  {
    Header: 'PPC S/ Mrg',
    accessor: ({ row }: any) => <Money value={row.ppc_s_mg_varejo} />,
    styles: styles(hues[0]),
  },
  // 2
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Tipo Tributação</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 2 - Impostos Cliente e Relatividade Canal
        </Text>
      </Flex>
    ),
    accessor: 'tipo_imposto',
    styles: styles(hues[1]),
  },
  {
    Header: 'PIS+COFINS Destino',
    accessor: ({ row }: any) => <Percent value={row.pis_cofins_destino} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'ICMS Destino',
    accessor: ({ row }: any) => <Percent value={row.icms_destino} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'MVA',
    accessor: ({ row }: any) => <Percent value={row.mva_percent} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'ICMS ST',
    accessor: ({ row }: any) => <Percent value={row.icms_st_percent} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'Mrg PPV Objetiva',
    accessor: ({ row }: any) => <Percent value={row.margem_objetiva} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'Relat. Canal',
    accessor: ({ row }: any) => <Percent value={row.relatividade_canal} />,
    styles: styles(hues[1]),
  },
  {
    Header: 'PPV Relat.',
    accessor: ({ row }: any) => <Money value={row.preco_relativo_canal} />,
    styles: styles(hues[1]),
  },
  // 3
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Mrg Mín</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 3 - Impostos Origem e Preço Sugestão
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.margem_min_valor} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'Mrg Máx',
    accessor: ({ row }: any) => <Money value={row.margem_max_valor} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <Percent value={row.adicional_contrato} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Atual',
    accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Novo',
    accessor: ({ row, rowIndex, inputsRef, updateRow, toggleBigData }: any) => (
      <PPVFinal
        row={row}
        rowIndex={rowIndex}
        inputsRef={inputsRef}
        updateRow={updateRow}
        toggleBigData={toggleBigData}
      />
    ),
    styles: styles(hues[2]),
  },
  {
    Header: 'PIS/Cofins Origem',
    accessor: ({ row }: any) => <Percent value={row.pis_cofins} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'ICMS Origem',
    accessor: ({ row }: any) => <Percent value={row.icms} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'IPI',
    accessor: ({ row }: any) => <Percent value={row.ipi_percent} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'PPV Bruto Novo',
    accessor: ({ row }: any) => <Money value={row.ppv_bruto} />,
    styles: styles(hues[2]),
  },
  {
    Header: 'Mrg PPV Novo',
    accessor: ({ row }: any) => <Percent value={row.margem_ppv_novo} />,
    styles: styles(hues[2]),
  },
  // 4
  {
    Header: () => (
      <Flex pos="relative" h="3.5rem" align="center">
        <Text>Novo PPC S/ Mrg</Text>
        <Text pos="absolute" left="0" top="-0.2rem" fontWeight="bold">
          STEP 4 - PPV Para PPC Novo
        </Text>
      </Flex>
    ),
    accessor: ({ row }: any) => <Money value={row.novo_ppc_sem_margem} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Mrg Mercado',
    accessor: ({ row }: any) => <Percent value={row.media_margem_varejo} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Novo PPC',
    accessor: ({ row }: any) => <Money value={row.ppc} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Desconto',
    accessor: ({ row }: any) => <Percent value={row.percentual_desconto} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Novo PPC Desconto',
    accessor: ({ row }: any) => <Money value={row.ppc_promocional} />,
    styles: styles(hues[3]),
  },
  {
    Header: 'Índice Final',
    accessor: ({ row }: any) => <Percent value={row.indice} />,
    styles: styles(hues[3]),
  },
  // ???
  // {
  //   Header: 'PPV Líquido Novo',
  //   accessor: ({ row }: any) => <Money value={row.ppv_liquido} />,
  // },
  // {
  //   Header: 'PPV Novo',
  //   accessor: ({ row }: any) => <Money value={row.ppv_sugestao} />,
  // },

  {
    Header: 'Custo Médio Produto',
    accessor: ({ row }: any) => <Money value={row.custo_medio_produto} />,
  },
  {
    Header: 'Volume Total',
    accessor: ({ row }: any) => <>{row.volume_total || '-'}</>,
  },
  {
    Header: 'Frete',
    accessor: ({ row }: any) => <Money value={row.frete} />,
  },
  {
    Header: 'Contrato',
    accessor: ({ row }: any) => <Money value={row.contrato} />,
  },
  // {
  //   Header: 'Relat. Canal',
  //   accessor: ({ row }: any) => <Percent value={row.relatividade_canal} />,
  // },
  // {
  //   Header: 'Desconto',
  //   accessor: ({ row }: any) => <Percent value={row.percentual_desconto} />,
  // },
  // {
  //   Header: 'PPV Atual',
  //   accessor: ({ row }: any) => <Money value={row.preco_liquido_unitario} />,
  // },
  // {
  //   Header: 'Outros',
  //   accessor: ({ row }: any) => <Money value={row.outros} />,
  // },
]

import { Fragment } from 'react'
import { Divider, Flex, Grid, Text } from '@chakra-ui/react'

interface IProps {
  data?: { [key: string]: any }
}

const cols = [
  { Header: 'Venda Mês', accessor: 'venda_mes' },
  { Header: '% Mês Tipo', accessor: 'mes_tipo' },
]

const SeasonalityMonth: React.FC<IProps> = ({ data }) => {
  if (!data) return null
  return (
    <Flex
      w="100%"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bgColor="#f5f6f8"
      overflow="hidden"
    >
      <Text fontWeight="semibold" fontSize="sm" p="1rem" bgColor="#fff">
        Performance do Produto - Mensal
      </Text>
      <Divider />
      <Grid
        w="100%"
        p="1rem"
        templateColumns="2fr repeat(12, 1fr)"
        gap="0.25rem"
      >
        <Flex w="100%">
          <Text></Text>
        </Flex>
        {data.weeks.map((day: string) => (
          <Flex w="100%" key={day}>
            <Text fontWeight="semibold">{day}</Text>
          </Flex>
        ))}
        {cols.map((col) => (
          <Fragment key={col.Header}>
            <Flex w="100%">
              <Text fontWeight="semibold">{col.Header}</Text>
            </Flex>
            {data.weeks.map((day: string) => (
              <Flex w="100%" key={day}>
                <Text>
                  {data[col.accessor][day]
                    .toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </Text>
              </Flex>
            ))}
          </Fragment>
        ))}
      </Grid>
    </Flex>
  )
}

export default SeasonalityMonth

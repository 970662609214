import { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  bundle: any
}

const Contract: React.FC<IProps> = ({ bundle }) => {
  const [contract, setContract] = useState(bundle.contract || '')

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setContract(e.target.value)
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const newVal = e.target.value || '0'
      setContract(newVal)

      const request = {
        bundle_id: bundle.id,
        client_id: bundle.client_id,
        contract: newVal,
      }

      api.put('/clusters/bundle/client', request)
    },
    [bundle.client_id, bundle.id]
  )

  return (
    <InputGroup w="10rem" size="sm">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        fontSize="1rem"
        maxW="7rem"
        type="number"
        name="contract"
        value={contract}
        onChange={onChange}
        onBlur={onBlur}
      />
    </InputGroup>
  )
}

export default Contract

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.span`
  font-size: 2rem;
  margin-left: 1rem;
  color: #003b74;
  font-weight: 500;
`

export const IconWrapper = styled.div`
  svg {
    font-size: 2rem;

    path {
      fill: #003b74;
    }
  }
`

import { useCallback, useState } from 'react'
import { Input, useToast } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'
import moment from 'moment'

interface IProps {
  row: IRow
}

const DateIni: React.FC<IProps> = ({ row }) => {
  const [value, setValue] = useState(row.date_ini)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = value
      setValue(e.target.value)

      if (row.date_end && moment(row.date_end).isBefore(e.target.value)) {
        return setValue(current)
      }

      const url = '/politics/update'
      const req = {
        id: row.id,
        date_ini: e.target.value,
      }

      api.post(url, req).catch(() => {
        setValue(row.date_ini)
      })
    },
    [row.date_end, row.date_ini, row.id, value]
  )

  return (
    <Input
      w="12rem"
      type="date"
      value={value}
      onChange={onChange}
      onKeyDown={(e) => e.preventDefault()}
    />
  )
}

export default DateIni

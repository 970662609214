const df64 = -0.4
const total_volume = 53418

const prod = [
  {
    fator: 0.889733,
    fator_normalizacao: [
      1.69, 1.11, 0.44, 0.59, 0.73, 0.85, 1.59, 1.69, 1.11, 0.44, 0.59, 0.73,
      0.85, 1.59, 1.69, 1.11, 0.44, 0.59, 0.73, 0.85, 1.59, 1.69, 1.11, 0.44,
      0.59, 0.73, 0.85, 1.59, 1.69, 1.11, 0.44,
    ],
    ajuste_concorrencia: -8.184779,
    quantidade_normal: 146.652601,
    valor_atual: 3.983873,
  },
  {
    fator: 0.997395,
    fator_normalizacao: [
      2.0, 1.1, 0.44, 0.47, 0.55, 0.88, 1.56, 2.0, 1.1, 0.44, 0.47, 0.55, 0.88,
      1.56, 2.0, 1.1, 0.44, 0.47, 0.55, 0.88, 1.56, 2.0, 1.1, 0.44, 0.47, 0.55,
      0.88, 1.56, 2.0, 1.1, 0.44,
    ],
    ajuste_concorrencia: -7.655929,
    quantidade_normal: 109.638974,
    valor_atual: 3.091366,
  },
  {
    fator: 1.06967,
    fator_normalizacao: [
      1.69, 1.17, 0.53, 0.7, 0.72, 0.91, 1.29, 1.69, 1.17, 0.53, 0.7, 0.72,
      0.91, 1.29, 1.69, 1.17, 0.53, 0.7, 0.72, 0.91, 1.29, 1.69, 1.17, 0.53,
      0.7, 0.72, 0.91, 1.29, 1.69, 1.17, 0.53,
    ],
    ajuste_concorrencia: -1.171183,
    quantidade_normal: 30.814163,
    valor_atual: 3.660773,
    total_volume: 53418,
  },
  {
    fator: 1.117878,
    fator_normalizacao: [
      1.26, 0.04, 1.67, 0.82, 1.59, 1.37, 0.25, 1.26, 0.04, 1.67, 0.82, 1.59,
      1.37, 0.25, 1.26, 0.04, 1.67, 0.82, 1.59, 1.37, 0.25, 1.26, 0.04, 1.67,
      0.82, 1.59, 1.37, 0.25, 1.26, 0.04, 1.67,
    ],
    ajuste_concorrencia: -7.497721,
    quantidade_normal: 34.749673,
    valor_atual: 52.617736,
  },
  {
    fator: 0.923659,
    fator_normalizacao: [
      1.93, 0.88, 0.55, 0.44, 0.36, 1.11, 1.74, 1.93, 0.88, 0.55, 0.44, 0.36,
      1.11, 1.74, 1.93, 0.88, 0.55, 0.44, 0.36, 1.11, 1.74, 1.93, 0.88, 0.55,
      0.44, 0.36, 1.11, 1.74, 1.93, 0.88, 0.55,
    ],
    ajuste_concorrencia: -4.643158,
    quantidade_normal: 23.006811,
    valor_atual: 3.871953,
  },
]

const calcValue = (valor: number, id: number) => {
  const porce_valor = valor / prod[id - 1].valor_atual

  const projecao_sem_concorrencia =
    prod[id - 1].quantidade_normal / Math.pow(porce_valor, 4)

  const projecao_normal =
    prod[id - 1].ajuste_concorrencia + projecao_sem_concorrencia

  let projecao_nominal = 0

  for (let i = 0; i < prod[id - 1].fator_normalizacao.length; i++) {
    const aux =
      projecao_normal * prod[id - 1].fator_normalizacao[i] * prod[id - 1].fator

    projecao_nominal = projecao_nominal + aux
  }

  const market_share = ((projecao_nominal / total_volume) * 100).toFixed(1)

  return {
    'quantidade_vendida_m+1': projecao_nominal,
    'market_share_do_produto_m+1': market_share,
    'preco_medio_ponderado_m+1': valor,
  }
}

export default calcValue

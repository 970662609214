import { Input, Text, VStack } from '@chakra-ui/react'

interface IProps {
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
}

const Name: React.FC<IProps> = ({ name, setName }) => {
  return (
    <VStack w="100%" align="flex-start">
      <Text>Nome</Text>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
    </VStack>
  )
}

export default Name

import { useCallback, useEffect, useState } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import api from 'services/api'
import Autocomplete from 'components/Autocomplete'

interface IProps {
  setClusterId: React.Dispatch<React.SetStateAction<string>>
}

const Cluster: React.FC<IProps> = ({ setClusterId }) => {
  const [value, setValue] = useState<IOption>({ label: '', value: '' })
  const [options, setOptions] = useState<IOption[]>([])

  const updateClusterId = useCallback(() => {
    setClusterId(value.value)
  }, [setClusterId, value.value])
  useEffect(updateClusterId, [updateClusterId])

  const getOptions = useCallback(() => {
    api.get('/clusters').then((response) => {
      setOptions(
        response.data.data.clusters.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      )
    })
  }, [])
  useEffect(getOptions, [getOptions])

  return (
    <VStack align="flex-start">
      <Text>Cluster</Text>
      <Autocomplete value={value} options={options} setValue={setValue} />
    </VStack>
  )
}

export default Cluster

import { Fragment } from 'react'
import { v4 as uuid } from 'uuid'
import { Divider, Flex, Grid, Text } from '@chakra-ui/react'

import Indicator from '../Indicator'

interface IProps {
  data?: { [key: string]: any }
}

const ProductsPositiveImpact: React.FC<IProps> = ({ data }) => {
  if (!data) return null
  return (
    <Flex
      w="100%"
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      bgColor="#f5f6f8"
      overflow="hidden"
    >
      <Grid
        w="100%"
        p="1rem"
        templateColumns="4fr repeat(2, 1fr)"
        gap="0.25rem"
        bgColor="#fff"
      >
        <Text fontWeight="semibold" fontSize="sm">
          Produtos Com Impacto Positivo
        </Text>
        <Flex w="100%" fontWeight="semibold" align="center" gap="0.5rem">
          Preços <Indicator />
        </Flex>
        <Flex w="100%" fontWeight="semibold" align="center" gap="0.5rem">
          Vendas <Indicator />
        </Flex>
      </Grid>
      <Divider />
      <Grid
        w="100%"
        p="1rem"
        templateColumns="4fr repeat(2, 1fr)"
        gap="0.25rem"
      >
        <Flex w="100%">
          <Text></Text>
        </Flex>
        <Flex w="100%">
          <Text fontWeight="semibold">Último Preço</Text>
        </Flex>
        <Flex w="100%">
          <Text fontWeight="semibold">Correlação</Text>
        </Flex>
        {data.map((product: any) => (
          <Fragment key={uuid()}>
            <Flex w="100%">
              <Text fontWeight="semibold">{product.product}</Text>
            </Flex>
            <Flex w="100%">
              <Text>{product.last_price}</Text>
            </Flex>
            <Flex w="100%">
              <Text>{product.correlation}</Text>
            </Flex>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  )
}

export default ProductsPositiveImpact

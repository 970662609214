export const MOCK_CLUSTER_OPT = {
  data: {
    clusters: [
      {
        id: 1,
        name: 'Cluster RJ',
        factory: '0',
      },
      // {
      //   id: 2,
      //   name: 'Cluster Volta Redonda',
      //   factory: '1',
      // },
    ],
  },
}

export const MOCK_PRODUCT_OPT = {
  data: {
    materials: [
      {
        id: 1,
        name: 'CERV ARTARCTICA LT 473ml',
        cluster_id: '1',
      },
      {
        id: 2,
        name: 'CERV BRAHMA LT 350ml DUPLO MALTE',
        cluster_id: '1',
      },
      {
        id: 3,
        name: 'CERV SKOL LT 500ml',
        cluster_id: '1',
      },
      {
        id: 4,
        name: 'CERV BRAHMA CHOPP LT 350ML PACK C/18',
        cluster_id: '2',
      },
      {
        id: 5,
        name: ' CERV ZERO ALCOOL BRAHMA LT 350ml',
        cluster_id: '1',
      },
    ],
  },
}

export const MOCK_PRODUCT_INFO: { [key: string]: any } = {
  data: {
    '1': {
      dados_historico: {
        quantidade_vendida_m2: '3.864',
        market_share_do_produto_m2: '5.4%',
        preco_medio_ponderado_m2: 'R$ 3,91',
        quantidade_vendida_m1: '3.800',
        market_share_do_produto_m1: '5.9%',
        preco_medio_ponderado_m1: 'R$ 3,99',
        quantidade_vendida_m0: '3.580',
        market_share_do_produto_m0: '6.3%',
        preco_medio_ponderado_m0: 'R$ 3,98',
        'quantidade_vendida_m+1': '3.859',
        'market_share_do_produto_m+1': '7.3%',
        'preco_medio_ponderado_m+1': 'R$ 3,98',
      },
      preco_praticar: '3.98',
      sazonalidade_do_produto_semana: {
        weeks: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dia_base: {
          Domingo: '49',
          Segunda: '49',
          Terça: '49',
          Quarta: '50',
          Quinta: '48',
          Sexta: '49',
          Sábado: '48',
        },
        qtd_media: {
          Domingo: '161',
          Segunda: '64',
          Terça: '87',
          Quarta: '106',
          Quinta: '124',
          Sexta: '232',
          Sábado: '247',
        },
        dia_tipo: {
          Domingo: '16%',
          Segunda: '6%',
          Terça: '8%',
          Quarta: '10%',
          Quinta: '12%',
          Sexta: '23%',
          Sábado: '24%',
        },
      },
      sazonalidade_do_produto_mensal: {
        weeks: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        venda_mes: {
          Jan: '3.710',
          Fev: '5.785',
          Mar: '3.512',
          Abr: '4.377',
          Mai: '3.022',
          Jun: '2.487',
          Jul: '3.864',
          Ago: '3.432',
          Set: '3.580',
          Out: '3.940',
          Nov: '3.834',
          Dez: '8.207',
        },
        mes_tipo: {
          Jan: '6%',
          Fev: '10%',
          Mar: '14%',
          Abr: '7%',
          Mai: '5%',
          Jun: '4%',
          Jul: '7%',
          Ago: '6%',
          Set: '6%',
          Out: '7%',
          Nov: '6%',
          Dez: '15%',
        },
      },
      produtos_com_impacto_positivo: [
        {
          product: 'CERV ZERO ALCOOL BRAHMA LT 350ml',
          last_price: 'R$ 4,29',
          correlation: 'FRACA',
        },
        {
          product: 'CERV ITAIPAVA LT 473ml',
          last_price: 'R$ 3,29',
          correlation: 'FRACA',
        },
        {
          product: 'CERV PETRA LT 473ml ORIGEM',
          last_price: '-',
          correlation: 'FRACA',
        },
      ],
      produtos_com_impacto_negativo: [
        {
          product: 'CERV MICHELOB ULTRA LIGHT LN 330ml',
          last_price: 'R$ 4,99',
          correlation: 'FORTE',
        },
        {
          product: 'CERV CORONA LN 330ml EXTRA',
          last_price: 'R$ 6,19',
          correlation: 'FORTE',
        },
        {
          product: 'CERV SKOL LT 550ml',
          last_price: 'R$ 3,91',
          correlation: 'FORTE',
        },
      ],
    },
    '2': {
      dados_historico: {
        quantidade_vendida_m2: '1.706',
        market_share_do_produto_m2: '3.5%',
        preco_medio_ponderado_m2: 'R$ 3,31',
        quantidade_vendida_m1: '3.096',
        market_share_do_produto_m1: '3.8%',
        preco_medio_ponderado_m1: 'R$ 3,06',
        quantidade_vendida_m0: '1.952',
        market_share_do_produto_m0: '4.1%',
        preco_medio_ponderado_m0: 'R$ 3,15',
        'quantidade_vendida_m+1': '2.948',
        'market_share_do_produto_m+1': '5.6%',
        'preco_medio_ponderado_m+1': 'R$ 3,15',
      },
      preco_praticar: '3.15',
      sazonalidade_do_produto_semana: {
        weeks: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dia_base: {
          Domingo: '49',
          Segunda: '49',
          Terça: '49',
          Quarta: '50',
          Quinta: '48',
          Sexta: '49',
          Sábado: '48',
        },
        qtd_media: {
          Domingo: '147',
          Segunda: '59',
          Terça: '62',
          Quarta: '74',
          Quinta: '118',
          Sexta: '210',
          Sábado: '269',
        },
        dia_tipo: {
          Domingo: '16%',
          Segunda: '6%',
          Terça: '7%',
          Quarta: '8%',
          Quinta: '13%',
          Sexta: '22%',
          Sábado: '29%',
        },
      },
      sazonalidade_do_produto_mensal: {
        weeks: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        venda_mes: {
          Jan: '2.692',
          Fev: '3.094',
          Mar: '1.189',
          Abr: '3.778',
          Mai: '2.578',
          Jun: '1.607',
          Jul: '1.706',
          Ago: '2.796',
          Set: '1.952',
          Out: '3.866',
          Nov: '8.547',
          Dez: '11.630',
        },
        mes_tipo: {
          Jan: '6%',
          Fev: '6%',
          Mar: '5%',
          Abr: '7%',
          Mai: '5%',
          Jun: '3%',
          Jul: '3%',
          Ago: '6%',
          Set: '4%',
          Out: '8%',
          Nov: '17%',
          Dez: '25%',
        },
      },
      produtos_com_impacto_positivo: [
        {
          product: 'CERV ITAIPAVA LT 350ml',
          last_price: 'R$ 2,69',
          correlation: 'FRACA',
        },
        {
          product: 'CERV ITAIPAVA LT 473ml',
          last_price: 'R$ 3,29',
          correlation: 'FRACA',
        },
        {
          product: 'CERV HEINEKEN LT 350ml',
          last_price: '-',
          correlation: 'FRACA',
        },
      ],
      produtos_com_impacto_negativo: [
        {
          product: 'CERV MICHELOB ULTRA LIGHT LN 330ml',
          last_price: 'R$ 4,99',
          correlation: 'FORTE',
        },
        {
          product: 'CERV BRAHMA LT 350ml DUPLO MALTE',
          last_price: 'R$ 2,99',
          correlation: 'FORTE',
        },
        {
          product: 'CERV CORONA LN 330ml EXTRA',
          last_price: 'R$ 6,19',
          correlation: 'FORTE',
        },
      ],
    },
    '3': {
      dados_historico: {
        quantidade_vendida_m2: '916',
        market_share_do_produto_m2: '1.8%',
        preco_medio_ponderado_m2: 'R$ 3,69',
        quantidade_vendida_m1: '797',
        market_share_do_produto_m1: '1.9%',
        preco_medio_ponderado_m1: 'R$ 3,69',
        quantidade_vendida_m0: '888',
        market_share_do_produto_m0: '2.1%',
        preco_medio_ponderado_m0: 'R$ 3,62',
        'quantidade_vendida_m+1': '1.037',
        'market_share_do_produto_m+1': '2.0%',
        'preco_medio_ponderado_m+1': 'R$ 3,62',
      },
      preco_praticar: '3.62',
      sazonalidade_do_produto_semana: {
        weeks: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dia_base: {
          Domingo: '49',
          Segunda: '49',
          Terça: '49',
          Quarta: '50',
          Quinta: '48',
          Sexta: '49',
          Sábado: '48',
        },
        qtd_media: {
          Domingo: '37',
          Segunda: '17',
          Terça: '22',
          Quarta: '23',
          Quinta: '29',
          Sexta: '41',
          Sábado: '54',
        },
        dia_tipo: {
          Domingo: '17%',
          Segunda: '8%',
          Terça: '10%',
          Quarta: '10%',
          Quinta: '13%',
          Sexta: '18%',
          Sábado: '24%',
        },
      },
      sazonalidade_do_produto_mensal: {
        weeks: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        venda_mes: {
          Jan: '530',
          Fev: '715',
          Mar: '479',
          Abr: '1.804',
          Mai: '950',
          Jun: '811',
          Jul: '916',
          Ago: '720',
          Set: '888',
          Out: '1.001',
          Nov: '991',
          Dez: '1.054',
        },
        mes_tipo: {
          Jan: '4%',
          Fev: '6%',
          Mar: '9%',
          Abr: '15%',
          Mai: '8%',
          Jun: '6%',
          Jul: '7%',
          Ago: '6%',
          Set: '7%',
          Out: '8%',
          Nov: '8%',
          Dez: '9%',
        },
      },
      produtos_com_impacto_positivo: [
        {
          product: 'CERV EISENBAHN LN 355ml',
          last_price: 'R$ 4,59',
          correlation: 'FRACA',
        },
        {
          product: 'CERV BRAHMA GRFA 300ml',
          last_price: 'R$ 2,19',
          correlation: 'FRACA',
        },
        {
          product: 'CERV EISENBAHN LT 473ml',
          last_price: 'R$ 4,79',
          correlation: 'FRACA',
        },
      ],
      produtos_com_impacto_negativo: [
        {
          product: 'CERV PETRA LT 473ml ORIGEM',
          last_price: '-',
          correlation: 'FORTE',
        },
        {
          product: 'CERV SKOL LT 473ml',
          last_price: 'R$ 3,34',
          correlation: 'FORTE',
        },
        {
          product: 'CERV 100 POR CENTO ITAIPAVA LT 473ml PURO MALTE',
          last_price: '-',
          correlation: 'FORTE',
        },
      ],
    },
    '4': {
      dados_historico: {
        quantidade_vendida_m2: '99',
        market_share_do_produto_m2: '0.4%',
        preco_medio_ponderado_m2: 'R$ 50,51',
        quantidade_vendida_m1: '2.579',
        market_share_do_produto_m1: '0.2%',
        preco_medio_ponderado_m1: 'R$ 52,39',
        quantidade_vendida_m0: '501',
        market_share_do_produto_m0: '0.3%',
        preco_medio_ponderado_m0: 'R$ 52,87',
        'quantidade_vendida_m+1': '921',
        'market_share_do_produto_m+1': '1.7%',
        'preco_medio_ponderado_m+1': 'R$ 52,87',
      },
      preco_praticar: '52.87',
      sazonalidade_do_produto_semana: {
        weeks: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dia_base: {
          Domingo: '49',
          Segunda: '48',
          Terça: '49',
          Quarta: '48',
          Quinta: '47',
          Sexta: '48',
          Sábado: '45',
        },
        qtd_media: {
          Domingo: '3',
          Segunda: '135',
          Terça: '66',
          Quarta: '128',
          Quinta: '111',
          Sexta: '21',
          Sábado: '102',
        },
        dia_tipo: {
          Domingo: '4%',
          Segunda: '167%',
          Terça: '82%',
          Quarta: '159%',
          Quinta: '137%',
          Sexta: '25%',
          Sábado: '126%',
        },
      },
      sazonalidade_do_produto_mensal: {
        weeks: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        venda_mes: {
          Jan: '79',
          Fev: '3.200',
          Mar: '8.710',
          Abr: '4.452',
          Mai: '4.411',
          Jun: '481',
          Jul: '89',
          Ago: '2.413',
          Set: '451',
          Out: '2.450',
          Nov: '11',
          Dez: '94',
        },
        mes_tipo: {
          Jan: '4%',
          Fev: '141%',
          Mar: '385%',
          Abr: '197%',
          Mai: '195%',
          Jun: '21%',
          Jul: '4%',
          Ago: '114%',
          Set: '22%',
          Out: '112%',
          Nov: '1%',
          Dez: '4%',
        },
      },
      produtos_com_impacto_positivo: [
        {
          product: 'CERV CRYSTAL LATA 473ML',
          last_price: '-',
          correlation: 'FRACA',
        },
        {
          product: 'CERV SKOL PURO MALTE LT 410ML',
          last_price: '-',
          correlation: 'FRACA',
        },
        {
          product: 'CERV TIGER PURO MALTE LATA 350ML',
          last_price: 'R$ 2,99',
          correlation: 'FRACA',
        },
      ],
      produtos_com_impacto_negativo: [
        {
          product: 'CERV BRAHMA CHOPP LT 350ML PACK C/18',
          last_price: 'R$ 52,82',
          correlation: 'FORTE',
        },
        {
          product: 'CERV SKOL PURO MALTE LATAO 473ML',
          last_price: 'R$ 3,19',
          correlation: 'FORTE',
        },
        {
          product: 'CERV SPATEN PURO MALTE LT 473ML',
          last_price: 'R$ 4,49',
          correlation: 'FORTE',
        },
      ],
    },
    '5': {
      dados_historico: {
        quantidade_vendida_m2: '429',
        market_share_do_produto_m2: '1.5%',
        preco_medio_ponderado_m2: 'R$ 3,99',
        quantidade_vendida_m1: '396',
        market_share_do_produto_m1: '1.0%',
        preco_medio_ponderado_m1: 'R$ 3,85',
        quantidade_vendida_m0: '148',
        market_share_do_produto_m0: '1.1%',
        preco_medio_ponderado_m0: 'R$ 3.87',
        'quantidade_vendida_m+1': '520',
        'market_share_do_produto_m+1': '1.0%',
        'preco_medio_ponderado_m+1': 'R$ 3,87',
      },
      preco_praticar: '3.87',
      sazonalidade_do_produto_semana: {
        weeks: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dia_base: {
          Domingo: '31',
          Segunda: '28',
          Terça: '31',
          Quarta: '30',
          Quinta: '31',
          Sexta: '30',
          Sábado: '31',
        },
        qtd_media: {
          Domingo: '30',
          Segunda: '28',
          Terça: '14',
          Quarta: '30',
          Quinta: '31',
          Sexta: '30',
          Sábado: '31',
        },
        dia_tipo: {
          Domingo: '13%',
          Segunda: '8%',
          Terça: '6%',
          Quarta: '5%',
          Quinta: '16%',
          Sexta: '25%',
          Sábado: '28%',
        },
      },
      sazonalidade_do_produto_mensal: {
        weeks: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        venda_mes: {
          Jan: '430',
          Fev: '881',
          Mar: '229',
          Abr: '883',
          Mai: '505',
          Jun: '370',
          Jul: '429',
          Ago: '358',
          Set: '148',
          Out: '505',
          Nov: '289',
          Dez: '1416',
        },
        mes_tipo: {
          Jan: '6%',
          Fev: '12%',
          Mar: '7%',
          Abr: '12%',
          Mai: '7%',
          Jun: '5%',
          Jul: '6%',
          Ago: '5%',
          Set: '2%',
          Out: '7%',
          Nov: '4%',
          Dez: '21%',
        },
      },
      produtos_com_impacto_positivo: [
        {
          product: 'CERV ZERO ALCOOL BRAHMA LT 350ml',
          last_price: 'R$ 4,29',
          correlation: 'FRACA',
        },
        {
          product: 'CERV ITAIPAVA LT 473ml',
          last_price: 'R$ 3,29',
          correlation: 'FRACA',
        },
        {
          product: 'CERV HEINEKEN LN 330ml',
          last_price: 'R$ 5,99',
          correlation: 'FRACA',
        },
      ],
      produtos_com_impacto_negativo: [
        {
          product: 'CERV MICHELOB ULTRA LIGHT LN 330ml',
          last_price: 'R$ 4,99',
          correlation: 'FORTE',
        },
        {
          product: 'CERV CORONA LN 330ml EXTRA',
          last_price: 'R$ 6,19',
          correlation: 'MEDIA',
        },
        {
          product: 'CERV PETRA LT 473ml PURO MALTE',
          last_price: 'R$ 3,25',
          correlation: 'MEDIA',
        },
      ],
    },
  },
}

export const MOCK_MARKET_SHARE_GRAPH: any = {
  '1': [
    { id: 1, month: 'M-3', value: 6.3 },
    { id: 2, month: 'M-2', value: 5.4 },
    { id: 3, month: 'M-1', value: 5.9 },
    { id: 4, month: 'M-0', value: 6.3 },
    { id: 5, month: 'M+1', value: 7.3 },
  ],
  '2': [
    { id: 1, month: 'M-3', value: 4.0 },
    { id: 2, month: 'M-2', value: 3.5 },
    { id: 3, month: 'M-1', value: 3.8 },
    { id: 4, month: 'M-0', value: 4.1 },
    { id: 5, month: 'M+1', value: 5.6 },
  ],
  '3': [
    { id: 1, month: 'M-3', value: 2.0 },
    { id: 2, month: 'M-2', value: 1.8 },
    { id: 3, month: 'M-1', value: 1.9 },
    { id: 4, month: 'M-0', value: 2.1 },
    { id: 5, month: 'M+1', value: 2.0 },
  ],
  '4': [
    { id: 1, month: 'M-3', value: 0.2 },
    { id: 2, month: 'M-2', value: 0.4 },
    { id: 3, month: 'M-1', value: 0.2 },
    { id: 4, month: 'M-0', value: 0.3 },
    { id: 5, month: 'M+1', value: 1.7 },
  ],
  '5': [
    { id: 107, month: 'M-3', value: 0.7 },
    { id: 107, month: 'M-2', value: 1.5 },
    { id: 107, month: 'M-1', value: 1.0 },
    { id: 107, month: 'M-0', value: 1.1 },
    { id: 107, month: 'M+1', value: 1.0 },
  ],
}

export const MOCK_MANUFACTURER_MARKET_SHARE_GRAPH: any = [
  {
    id: 1,
    month: 'M-3',
    manufacturers: [
      { id: 1, name: 'Coca Cola', value: 4.6 },
      { id: 2, name: 'Imperio', value: 0.4 },
      { id: 3, name: 'Petropolis', value: 9.2 },
      { id: 4, name: 'Ambev', value: 82.0 },
      { id: 5, name: 'Heineken', value: 3.4 },
      { id: 6, name: 'Outras', value: 0.4 },
    ],
  },
  {
    id: 2,
    month: 'M-2',
    manufacturers: [
      { id: 1, name: 'Coca Cola', value: 4.6 },
      { id: 2, name: 'Imperio', value: 0.4 },
      { id: 3, name: 'Petropolis', value: 16.4 },
      { id: 4, name: 'Ambev', value: 70.7 },
      { id: 5, name: 'Heineken', value: 5.8 },
      { id: 6, name: 'Outras', value: 0.4 },
    ],
  },
  {
    id: 3,
    month: 'M-1',
    manufacturers: [
      { id: 1, name: 'Coca Cola', value: 5.9 },
      { id: 2, name: 'Imperio', value: 0.3 },
      { id: 3, name: 'Petropolis', value: 18.4 },
      { id: 4, name: 'Ambev', value: 72.0 },
      { id: 5, name: 'Heineken', value: 3.2 },
      { id: 6, name: 'Outras', value: 0.3 },
    ],
  },
  {
    id: 4,
    month: 'M-0',
    manufacturers: [
      { id: 1, name: 'Coca Cola', value: 6.8 },
      { id: 2, name: 'Imperio', value: 0.2 },
      { id: 3, name: 'Petropolis', value: 22.3 },
      { id: 4, name: 'Ambev', value: 57.9 },
      { id: 5, name: 'Heineken', value: 12.6 },
      { id: 6, name: 'Outras', value: 0.2 },
    ],
  },
]

export const MOCK_PREDICTED_GRAPH = [
  {
    id: 1,
    date: '2022-06-01',
    previsto: 72,
    realizado: 70,
  },
  {
    id: 2,
    date: '2022-06-02',
    previsto: 141,
    realizado: 79,
  },
  {
    id: 3,
    date: '2022-06-03',
    previsto: 194,
    realizado: 153,
  },
  {
    id: 4,
    date: '2022-06-04',
    previsto: 176,
    realizado: 157,
  },
  {
    id: 5,
    date: '2022-06-05',
    previsto: 67,
    realizado: 103,
  },
  {
    id: 6,
    date: '2022-06-06',
    previsto: 19,
    realizado: 42,
  },
  {
    id: 7,
    date: '2022-06-07',
    previsto: 50,
    realizado: 57,
  },
  {
    id: 8,
    date: '2022-06-08',
    previsto: 70,
    realizado: 69,
  },
  {
    id: 9,
    date: '2022-06-09',
    previsto: 83,
    realizado: 78,
  },
  {
    id: 10,
    date: '2022-06-10',
    previsto: 130,
    realizado: 148,
  },
  {
    id: 11,
    date: '2022-06-11',
    previsto: 109,
    realizado: 157,
  },
  {
    id: 12,
    date: '2022-06-12',
    previsto: 82,
    realizado: 103,
  },
  {
    id: 13,
    date: '2022-06-13',
    previsto: 48,
    realizado: 41,
  },
  {
    id: 14,
    date: '2022-06-14',
    previsto: 36,
    realizado: 55,
  },
  {
    id: 15,
    date: '2022-06-15',
    previsto: 139,
    realizado: 68,
  },
  {
    id: 16,
    date: '2022-06-16',
    previsto: 125,
    realizado: 78,
  },
  {
    id: 17,
    date: '2022-06-17',
    previsto: 47,
    realizado: 148,
  },
  {
    id: 18,
    date: '2022-06-18',
    previsto: 71,
    realizado: 157,
  },
  {
    id: 19,
    date: '2022-06-19',
    previsto: 19,
    realizado: 103,
  },
  {
    id: 20,
    date: '2022-06-20',
    previsto: 4,
    realizado: 42,
  },
  {
    id: 21,
    date: '2022-06-21',
    previsto: 44,
    realizado: 57,
  },
  {
    id: 22,
    date: '2022-06-22',
    previsto: 51,
    realizado: 67,
  },
  {
    id: 23,
    date: '2022-06-23',
    previsto: 52,
    realizado: 79,
  },
  {
    id: 24,
    date: '2022-06-24',
    previsto: 139,
    realizado: 148,
  },
  {
    id: 25,
    date: '2022-06-25',
    previsto: 145,
    realizado: 141,
  },
  {
    id: 26,
    date: '2022-06-26',
    previsto: 68,
    realizado: 92,
  },
  {
    id: 27,
    date: '2022-06-27',
    previsto: 81,
    realizado: 37,
  },
  {
    id: 28,
    date: '2022-06-28',
    previsto: 48,
    realizado: 51,
  },
  {
    id: 29,
    date: '2022-06-29',
    previsto: 90,
    realizado: 65,
  },
  {
    id: 30,
    date: '2022-06-30',
    previsto: 87,
    realizado: 71,
  },
  {
    id: 31,
    date: '2022-07-01',
    previsto: 354,
    realizado: 206,
  },
  {
    id: 32,
    date: '2022-07-02',
    previsto: 213,
    realizado: 219,
  },
  {
    id: 33,
    date: '2022-07-03',
    previsto: 92,
    realizado: 143,
  },
  {
    id: 34,
    date: '2022-07-04',
    previsto: 46,
    realizado: 59,
  },
  {
    id: 35,
    date: '2022-07-05',
    previsto: 49,
    realizado: 77,
  },
  {
    id: 36,
    date: '2022-07-06',
    previsto: 142,
    realizado: 93,
  },
  {
    id: 37,
    date: '2022-07-07',
    previsto: 103,
    realizado: 115,
  },
  {
    id: 38,
    date: '2022-07-08',
    previsto: 198,
    realizado: 205,
  },
  {
    id: 39,
    date: '2022-07-09',
    previsto: 214,
    realizado: 219,
  },
  {
    id: 40,
    date: '2022-07-10',
    previsto: 188,
    realizado: 142,
  },
  {
    id: 41,
    date: '2022-07-11',
    previsto: 33,
    realizado: 58,
  },
  {
    id: 42,
    date: '2022-07-12',
    previsto: 58,
    realizado: 80,
  },
  {
    id: 43,
    date: '2022-07-13',
    previsto: 87,
    realizado: 93,
  },
  {
    id: 44,
    date: '2022-07-14',
    previsto: 86,
    realizado: 110,
  },
  {
    id: 45,
    date: '2022-07-18',
    previsto: 20,
    realizado: 57,
  },
  {
    id: 46,
    date: '2022-07-19',
    previsto: 52,
    realizado: 77,
  },
  {
    id: 47,
    date: '2022-07-20',
    previsto: 47,
    realizado: 93,
  },
  {
    id: 48,
    date: '2022-07-21',
    previsto: 47,
    realizado: 110,
  },
  {
    id: 49,
    date: '2022-07-22',
    previsto: 271,
    realizado: 206,
  },
  {
    id: 50,
    date: '2022-07-23',
    previsto: 188,
    realizado: 227,
  },
  {
    id: 51,
    date: '2022-07-24',
    previsto: 83,
    realizado: 143,
  },
  {
    id: 52,
    date: '2022-07-25',
    previsto: 63,
    realizado: 61,
  },
  {
    id: 53,
    date: '2022-07-26',
    previsto: 32,
    realizado: 79,
  },
  {
    id: 54,
    date: '2022-07-27',
    previsto: 47,
    realizado: 85,
  },
  {
    id: 55,
    date: '2022-07-28',
    previsto: 63,
    realizado: 103,
  },
  {
    id: 56,
    date: '2022-07-29',
    previsto: 299,
    realizado: 193,
  },
  {
    id: 57,
    date: '2022-07-30',
    previsto: 177,
    realizado: 197,
  },
  {
    id: 58,
    date: '2022-07-31',
    previsto: 126,
    realizado: 135,
  },
  {
    id: 59,
    date: '2022-08-02',
    previsto: 97,
    realizado: 71,
  },
  {
    id: 60,
    date: '2022-08-03',
    previsto: 50,
    realizado: 83,
  },
  {
    id: 61,
    date: '2022-08-04',
    previsto: 73,
    realizado: 101,
  },
  {
    id: 62,
    date: '2022-08-05',
    previsto: 149,
    realizado: 181,
  },
  {
    id: 61,
    date: '2022-08-06',
    previsto: 270,
    realizado: 213,
  },
  {
    id: 62,
    date: '2022-08-07',
    previsto: 117,
    realizado: 132,
  },
  {
    id: 63,
    date: '2022-08-08',
    previsto: 29,
    realizado: 52,
  },
  {
    id: 64,
    date: '2022-08-09',
    previsto: 86,
    realizado: 71,
  },
  {
    id: 65,
    date: '2022-08-10',
    previsto: 29,
    realizado: 87,
  },
  {
    id: 66,
    date: '2022-08-12',
    previsto: 148,
    realizado: 190,
  },
  {
    id: 67,
    date: '2022-08-13',
    previsto: 522,
    realizado: 202,
  },
  {
    id: 68,
    date: '2022-08-14',
    previsto: 478,
    realizado: 138,
  },
  {
    id: 69,
    date: '2022-08-15',
    previsto: 75,
    realizado: 51,
  },
  {
    id: 70,
    date: '2022-08-16',
    previsto: 119,
    realizado: 71,
  },
  {
    id: 71,
    date: '2022-08-17',
    previsto: 76,
    realizado: 87,
  },
  {
    id: 72,
    date: '2022-08-19',
    previsto: 104,
    realizado: 190,
  },
  {
    id: 73,
    date: '2022-08-20',
    previsto: 242,
    realizado: 210,
  },
  {
    id: 74,
    date: '2022-08-22',
    previsto: 55,
    realizado: 52,
  },
  {
    id: 75,
    date: '2022-08-23',
    previsto: 22,
    realizado: 71,
  },
  {
    id: 76,
    date: '2022-08-24',
    previsto: 82,
    realizado: 87,
  },
  {
    id: 77,
    date: '2022-08-25',
    previsto: 68,
    realizado: 102,
  },
  {
    id: 78,
    date: '2022-08-27',
    previsto: 210,
    realizado: 211,
  },
  {
    id: 79,
    date: '2022-08-28',
    previsto: 110,
    realizado: 132,
  },
  {
    id: 80,
    date: '2022-08-29',
    previsto: 29,
    realizado: 52,
  },
  {
    id: 81,
    date: '2022-08-30',
    previsto: 32,
    realizado: 70,
  },
  {
    id: 82,
    date: '2022-08-31',
    previsto: 48,
    realizado: 91,
  },
  {
    id: 83,
    date: '2022-09-02',
    previsto: 110,
    realizado: 179,
  },
  {
    id: 84,
    date: '2022-09-03',
    previsto: 200,
    realizado: 190,
  },
  {
    id: 85,
    date: '2022-09-04',
    previsto: 89,
    realizado: 119,
  },
  {
    id: 86,
    date: '2022-09-05',
    previsto: 18,
    realizado: 46,
  },
  {
    id: 87,
    date: '2022-09-06',
    previsto: 104,
    realizado: 67,
  },
  {
    id: 88,
    date: '2022-09-07',
    previsto: 173,
    realizado: 78,
  },
  {
    id: 89,
    date: '2022-09-08',
    previsto: 54,
    realizado: 91,
  },
  {
    id: 90,
    date: '2022-09-09',
    previsto: 280,
    realizado: 171,
  },
  {
    id: 91,
    date: '2022-09-10',
    previsto: 160,
    realizado: 182,
  },
  {
    id: 92,
    date: '2022-09-11',
    previsto: 151,
    realizado: 123,
  },
  {
    id: 93,
    date: '2022-09-12',
    previsto: 150,
    realizado: 46,
  },
  {
    id: 94,
    date: '2022-09-13',
    previsto: 86,
    realizado: 82,
  },
  {
    id: 95,
    date: '2022-09-15',
    previsto: 100,
    realizado: 99,
  },
  {
    id: 96,
    date: '2022-09-17',
    previsto: 148,
    realizado: 182,
  },
  {
    id: 97,
    date: '2022-09-18',
    previsto: 126,
    realizado: 117,
  },
  {
    id: 98,
    date: '2022-09-20',
    previsto: 14,
    realizado: 64,
  },
  {
    id: 99,
    date: '2022-09-21',
    previsto: 24,
    realizado: 81,
  },
  {
    id: 100,
    date: '2022-09-23',
    previsto: 154,
    realizado: 171,
  },
  {
    id: 101,
    date: '2022-09-25',
    previsto: 139,
    realizado: 117,
  },
  {
    id: 102,
    date: '2022-09-26',
    previsto: 43,
    realizado: 48,
  },
  {
    id: 103,
    date: '2022-09-29',
    previsto: 5,
    realizado: 94,
  },
  {
    id: 104,
    date: '2022-09-30',
    previsto: 162,
    realizado: 171,
  },
]

// ########################### material 1 ####################################
export const MOCK_PREDICTED_GRAPH_1 = [
  {
    id: 1,
    date: '2022-06-01',
    previsto: 72,
    realizado: 70,
  },
  {
    id: 2,
    date: '2022-06-02',
    previsto: 141,
    realizado: 79,
  },
  {
    id: 3,
    date: '2022-06-03',
    previsto: 194,
    realizado: 153,
  },
  {
    id: 4,
    date: '2022-06-04',
    previsto: 176,
    realizado: 157,
  },
  {
    id: 5,
    date: '2022-06-05',
    previsto: 67,
    realizado: 103,
  },
  {
    id: 6,
    date: '2022-06-06',
    previsto: 19,
    realizado: 42,
  },
  {
    id: 7,
    date: '2022-06-07',
    previsto: 50,
    realizado: 57,
  },
  {
    id: 8,
    date: '2022-06-08',
    previsto: 70,
    realizado: 69,
  },
  {
    id: 9,
    date: '2022-06-09',
    previsto: 83,
    realizado: 78,
  },
  {
    id: 10,
    date: '2022-06-10',
    previsto: 130,
    realizado: 148,
  },
  {
    id: 11,
    date: '2022-06-11',
    previsto: 109,
    realizado: 157,
  },
  {
    id: 12,
    date: '2022-06-12',
    previsto: 82,
    realizado: 103,
  },
  {
    id: 13,
    date: '2022-06-13',
    previsto: 48,
    realizado: 41,
  },
  {
    id: 14,
    date: '2022-06-14',
    previsto: 36,
    realizado: 55,
  },
  {
    id: 15,
    date: '2022-06-15',
    previsto: 139,
    realizado: 68,
  },
  {
    id: 16,
    date: '2022-06-16',
    previsto: 125,
    realizado: 78,
  },
  {
    id: 17,
    date: '2022-06-17',
    previsto: 47,
    realizado: 148,
  },
  {
    id: 18,
    date: '2022-06-18',
    previsto: 71,
    realizado: 157,
  },
  {
    id: 19,
    date: '2022-06-19',
    previsto: 19,
    realizado: 103,
  },
  {
    id: 20,
    date: '2022-06-20',
    previsto: 4,
    realizado: 42,
  },
  {
    id: 21,
    date: '2022-06-21',
    previsto: 44,
    realizado: 57,
  },
  {
    id: 22,
    date: '2022-06-22',
    previsto: 51,
    realizado: 67,
  },
  {
    id: 23,
    date: '2022-06-23',
    previsto: 52,
    realizado: 79,
  },
  {
    id: 24,
    date: '2022-06-24',
    previsto: 139,
    realizado: 148,
  },
  {
    id: 25,
    date: '2022-06-25',
    previsto: 145,
    realizado: 141,
  },
  {
    id: 26,
    date: '2022-06-26',
    previsto: 68,
    realizado: 92,
  },
  {
    id: 27,
    date: '2022-06-27',
    previsto: 81,
    realizado: 37,
  },
  {
    id: 28,
    date: '2022-06-28',
    previsto: 48,
    realizado: 51,
  },
  {
    id: 29,
    date: '2022-06-29',
    previsto: 90,
    realizado: 65,
  },
  {
    id: 30,
    date: '2022-06-30',
    previsto: 87,
    realizado: 71,
  },
  {
    id: 31,
    date: '2022-07-01',
    previsto: 354,
    realizado: 206,
  },
  {
    id: 32,
    date: '2022-07-02',
    previsto: 213,
    realizado: 219,
  },
  {
    id: 33,
    date: '2022-07-03',
    previsto: 92,
    realizado: 143,
  },
  {
    id: 34,
    date: '2022-07-04',
    previsto: 46,
    realizado: 59,
  },
  {
    id: 35,
    date: '2022-07-05',
    previsto: 49,
    realizado: 77,
  },
  {
    id: 36,
    date: '2022-07-06',
    previsto: 142,
    realizado: 93,
  },
  {
    id: 37,
    date: '2022-07-07',
    previsto: 103,
    realizado: 115,
  },
  {
    id: 38,
    date: '2022-07-08',
    previsto: 198,
    realizado: 205,
  },
  {
    id: 39,
    date: '2022-07-09',
    previsto: 214,
    realizado: 219,
  },
  {
    id: 40,
    date: '2022-07-10',
    previsto: 188,
    realizado: 142,
  },
  {
    id: 41,
    date: '2022-07-11',
    previsto: 33,
    realizado: 58,
  },
  {
    id: 42,
    date: '2022-07-12',
    previsto: 58,
    realizado: 80,
  },
  {
    id: 43,
    date: '2022-07-13',
    previsto: 87,
    realizado: 93,
  },
  {
    id: 44,
    date: '2022-07-14',
    previsto: 86,
    realizado: 110,
  },
  {
    id: 45,
    date: '2022-07-18',
    previsto: 20,
    realizado: 57,
  },
  {
    id: 46,
    date: '2022-07-19',
    previsto: 52,
    realizado: 77,
  },
  {
    id: 47,
    date: '2022-07-20',
    previsto: 47,
    realizado: 93,
  },
  {
    id: 48,
    date: '2022-07-21',
    previsto: 47,
    realizado: 110,
  },
  {
    id: 49,
    date: '2022-07-22',
    previsto: 271,
    realizado: 206,
  },
  {
    id: 50,
    date: '2022-07-23',
    previsto: 188,
    realizado: 227,
  },
  {
    id: 51,
    date: '2022-07-24',
    previsto: 83,
    realizado: 143,
  },
  {
    id: 52,
    date: '2022-07-25',
    previsto: 63,
    realizado: 61,
  },
  {
    id: 53,
    date: '2022-07-26',
    previsto: 32,
    realizado: 79,
  },
  {
    id: 54,
    date: '2022-07-27',
    previsto: 47,
    realizado: 85,
  },
  {
    id: 55,
    date: '2022-07-28',
    previsto: 63,
    realizado: 103,
  },
  {
    id: 56,
    date: '2022-07-29',
    previsto: 299,
    realizado: 193,
  },
  {
    id: 57,
    date: '2022-07-30',
    previsto: 177,
    realizado: 197,
  },
  {
    id: 58,
    date: '2022-07-31',
    previsto: 126,
    realizado: 135,
  },
  {
    id: 59,
    date: '2022-08-02',
    previsto: 97,
    realizado: 71,
  },
  {
    id: 60,
    date: '2022-08-03',
    previsto: 50,
    realizado: 83,
  },
  {
    id: 61,
    date: '2022-08-04',
    previsto: 73,
    realizado: 101,
  },
  {
    id: 62,
    date: '2022-08-05',
    previsto: 149,
    realizado: 181,
  },
  {
    id: 61,
    date: '2022-08-06',
    previsto: 270,
    realizado: 213,
  },
  {
    id: 62,
    date: '2022-08-07',
    previsto: 117,
    realizado: 132,
  },
  {
    id: 63,
    date: '2022-08-08',
    previsto: 29,
    realizado: 52,
  },
  {
    id: 64,
    date: '2022-08-09',
    previsto: 86,
    realizado: 71,
  },
  {
    id: 65,
    date: '2022-08-10',
    previsto: 29,
    realizado: 87,
  },
  {
    id: 66,
    date: '2022-08-12',
    previsto: 148,
    realizado: 190,
  },
  {
    id: 67,
    date: '2022-08-13',
    previsto: 522,
    realizado: 202,
  },
  {
    id: 68,
    date: '2022-08-14',
    previsto: 478,
    realizado: 138,
  },
  {
    id: 69,
    date: '2022-08-15',
    previsto: 75,
    realizado: 51,
  },
  {
    id: 70,
    date: '2022-08-16',
    previsto: 119,
    realizado: 71,
  },
  {
    id: 71,
    date: '2022-08-17',
    previsto: 76,
    realizado: 87,
  },
  {
    id: 72,
    date: '2022-08-19',
    previsto: 104,
    realizado: 190,
  },
  {
    id: 73,
    date: '2022-08-20',
    previsto: 242,
    realizado: 210,
  },
  {
    id: 74,
    date: '2022-08-22',
    previsto: 55,
    realizado: 52,
  },
  {
    id: 75,
    date: '2022-08-23',
    previsto: 22,
    realizado: 71,
  },
  {
    id: 76,
    date: '2022-08-24',
    previsto: 82,
    realizado: 87,
  },
  {
    id: 77,
    date: '2022-08-25',
    previsto: 68,
    realizado: 102,
  },
  {
    id: 78,
    date: '2022-08-27',
    previsto: 210,
    realizado: 211,
  },
  {
    id: 79,
    date: '2022-08-28',
    previsto: 110,
    realizado: 132,
  },
  {
    id: 80,
    date: '2022-08-29',
    previsto: 29,
    realizado: 52,
  },
  {
    id: 81,
    date: '2022-08-30',
    previsto: 32,
    realizado: 70,
  },
  {
    id: 82,
    date: '2022-08-31',
    previsto: 48,
    realizado: 91,
  },
  {
    id: 83,
    date: '2022-09-02',
    previsto: 110,
    realizado: 179,
  },
  {
    id: 84,
    date: '2022-09-03',
    previsto: 200,
    realizado: 190,
  },
  {
    id: 85,
    date: '2022-09-04',
    previsto: 89,
    realizado: 119,
  },
  {
    id: 86,
    date: '2022-09-05',
    previsto: 18,
    realizado: 46,
  },
  {
    id: 87,
    date: '2022-09-06',
    previsto: 104,
    realizado: 67,
  },
  {
    id: 88,
    date: '2022-09-07',
    previsto: 173,
    realizado: 78,
  },
  {
    id: 89,
    date: '2022-09-08',
    previsto: 54,
    realizado: 91,
  },
  {
    id: 90,
    date: '2022-09-09',
    previsto: 280,
    realizado: 171,
  },
  {
    id: 91,
    date: '2022-09-10',
    previsto: 160,
    realizado: 182,
  },
  {
    id: 92,
    date: '2022-09-11',
    previsto: 151,
    realizado: 123,
  },
  {
    id: 93,
    date: '2022-09-12',
    previsto: 150,
    realizado: 46,
  },
  {
    id: 94,
    date: '2022-09-13',
    previsto: 86,
    realizado: 82,
  },
  {
    id: 95,
    date: '2022-09-15',
    previsto: 100,
    realizado: 99,
  },
  {
    id: 96,
    date: '2022-09-17',
    previsto: 148,
    realizado: 182,
  },
  {
    id: 97,
    date: '2022-09-18',
    previsto: 126,
    realizado: 117,
  },
  {
    id: 98,
    date: '2022-09-20',
    previsto: 14,
    realizado: 64,
  },
  {
    id: 99,
    date: '2022-09-21',
    previsto: 24,
    realizado: 81,
  },
  {
    id: 100,
    date: '2022-09-23',
    previsto: 154,
    realizado: 171,
  },
  {
    id: 101,
    date: '2022-09-25',
    previsto: 139,
    realizado: 117,
  },
  {
    id: 102,
    date: '2022-09-26',
    previsto: 43,
    realizado: 48,
  },
  {
    id: 103,
    date: '2022-09-29',
    previsto: 5,
    realizado: 94,
  },
  {
    id: 104,
    date: '2022-09-30',
    previsto: 162,
    realizado: 171,
  },
]

// ########################################### MATERIAL 2 ###############################################

export const MOCK_PREDICTED_GRAPH_2 = [
  {
    id: 1,
    date: '2022-06-01',
    previsto: 54,
    realizado: 27,
  },
  {
    id: 2,
    date: '2022-06-02',
    previsto: 31,
    realizado: 42,
  },
  {
    id: 3,
    date: '2022-06-03',
    previsto: 96,
    realizado: 74,
  },
  {
    id: 4,
    date: '2022-06-04',
    previsto: 117,
    realizado: 94,
  },
  {
    id: 5,
    date: '2022-06-05',
    previsto: 55,
    realizado: 52,
  },
  {
    id: 6,
    date: '2022-06-06',
    previsto: 33,
    realizado: 22,
  },
  {
    id: 7,
    date: '2022-06-07',
    previsto: 11,
    realizado: 23,
  },
  {
    id: 8,
    date: '2022-06-08',
    previsto: 60,
    realizado: 27,
  },
  {
    id: 9,
    date: '2022-06-09',
    previsto: 39,
    realizado: 42,
  },
  {
    id: 10,
    date: '2022-06-10',
    previsto: 75,
    realizado: 65,
  },
  {
    id: 11,
    date: '2022-06-11',
    previsto: 103,
    realizado: 94,
  },
  {
    id: 12,
    date: '2022-06-12',
    previsto: 131,
    realizado: 51,
  },
  {
    id: 13,
    date: '2022-06-13',
    previsto: 23,
    realizado: 21,
  },
  {
    id: 14,
    date: '2022-06-14',
    previsto: 7,
    realizado: 22,
  },
  {
    id: 15,
    date: '2022-06-15',
    previsto: 47,
    realizado: 26,
  },
  {
    id: 16,
    date: '2022-06-16',
    previsto: 91,
    realizado: 42,
  },
  {
    id: 17,
    date: '2022-06-17',
    previsto: 68,
    realizado: 74,
  },
  {
    id: 18,
    date: '2022-06-18',
    previsto: 48,
    realizado: 95,
  },
  {
    id: 19,
    date: '2022-06-19',
    previsto: 52,
    realizado: 51,
  },
  {
    id: 20,
    date: '2022-06-20',
    previsto: 19,
    realizado: 20,
  },
  {
    id: 21,
    date: '2022-06-21',
    previsto: 7,
    realizado: 23,
  },
  {
    id: 22,
    date: '2022-06-22',
    previsto: 52,
    realizado: 23,
  },
  {
    id: 23,
    date: '2022-06-23',
    previsto: 55,
    realizado: 42,
  },
  {
    id: 24,
    date: '2022-06-24',
    previsto: 106,
    realizado: 69,
  },
  {
    id: 25,
    date: '2022-06-25',
    previsto: 66,
    realizado: 71,
  },
  {
    id: 26,
    date: '2022-06-26',
    previsto: 35,
    realizado: 38,
  },
  {
    id: 27,
    date: '2022-06-27',
    previsto: 13,
    realizado: 16,
  },
  {
    id: 28,
    date: '2022-06-28',
    previsto: 15,
    realizado: 17,
  },
  {
    id: 29,
    date: '2022-06-29',
    previsto: 59,
    realizado: 21,
  },
  {
    id: 30,
    date: '2022-06-30',
    previsto: 39,
    realizado: 31,
  },
  {
    id: 31,
    date: '2022-07-01',
    previsto: 47,
    realizado: 51,
  },
  {
    id: 32,
    date: '2022-07-02',
    previsto: 114,
    realizado: 66,
  },
  {
    id: 33,
    date: '2022-07-03',
    previsto: 22,
    realizado: 36,
  },
  {
    id: 34,
    date: '2022-07-04',
    previsto: 32,
    realizado: 16,
  },
  {
    id: 35,
    date: '2022-07-05',
    previsto: 21,
    realizado: 15,
  },
  {
    id: 36,
    date: '2022-07-06',
    previsto: 28,
    realizado: 18,
  },
  {
    id: 37,
    date: '2022-07-07',
    previsto: 33,
    realizado: 31,
  },
  {
    id: 38,
    date: '2022-07-08',
    previsto: 68,
    realizado: 51,
  },
  {
    id: 39,
    date: '2022-07-09',
    previsto: 83,
    realizado: 64,
  },
  {
    id: 40,
    date: '2022-07-10',
    previsto: 49,
    realizado: 34,
  },
  {
    id: 41,
    date: '2022-07-11',
    previsto: 53,
    realizado: 15,
  },
  {
    id: 42,
    date: '2022-07-12',
    previsto: 25,
    realizado: 17,
  },
  {
    id: 43,
    date: '2022-07-13',
    previsto: 63,
    realizado: 18,
  },
  {
    id: 44,
    date: '2022-07-14',
    previsto: 54,
    realizado: 29,
  },
  {
    id: 45,
    date: '2022-07-18',
    previsto: 50,
    realizado: 14,
  },
  {
    id: 46,
    date: '2022-07-19',
    previsto: 38,
    realizado: 15,
  },
  {
    id: 47,
    date: '2022-07-20',
    previsto: 32,
    realizado: 18,
  },
  {
    id: 48,
    date: '2022-07-21',
    previsto: 62,
    realizado: 28,
  },
  {
    id: 49,
    date: '2022-07-22',
    previsto: 46,
    realizado: 51,
  },
  {
    id: 50,
    date: '2022-07-23',
    previsto: 133,
    realizado: 66,
  },
  {
    id: 51,
    date: '2022-07-24',
    previsto: 79,
    realizado: 36,
  },
  {
    id: 52,
    date: '2022-07-25',
    previsto: 37,
    realizado: 16,
  },
  {
    id: 53,
    date: '2022-07-26',
    previsto: 7,
    realizado: 17,
  },
  {
    id: 54,
    date: '2022-07-27',
    previsto: 50,
    realizado: 16,
  },
  {
    id: 55,
    date: '2022-07-28',
    previsto: 16,
    realizado: 27,
  },
  {
    id: 56,
    date: '2022-07-29',
    previsto: 48,
    realizado: 49,
  },
  {
    id: 57,
    date: '2022-07-30',
    previsto: 90,
    realizado: 58,
  },
  {
    id: 58,
    date: '2022-07-31',
    previsto: 12,
    realizado: 33,
  },
  {
    id: 59,
    date: '2022-08-02',
    previsto: 16,
    realizado: 27,
  },
  {
    id: 60,
    date: '2022-08-03',
    previsto: 30,
    realizado: 29,
  },
  {
    id: 61,
    date: '2022-08-04',
    previsto: 22,
    realizado: 46,
  },
  {
    id: 62,
    date: '2022-08-05',
    previsto: 273,
    realizado: 142,
  },
  {
    id: 63,
    date: '2022-08-06',
    previsto: 646,
    realizado: 192,
  },
  {
    id: 64,
    date: '2022-08-07',
    previsto: 70,
    realizado: 105,
  },
  {
    id: 65,
    date: '2022-08-08',
    previsto: 36,
    realizado: 25,
  },
  {
    id: 66,
    date: '2022-08-09',
    previsto: 50,
    realizado: 27,
  },
  {
    id: 67,
    date: '2022-08-10',
    previsto: 29,
    realizado: 31,
  },
  {
    id: 68,
    date: '2022-08-12',
    previsto: 270,
    realizado: 150,
  },
  {
    id: 69,
    date: '2022-08-13',
    previsto: 768,
    realizado: 192,
  },
  {
    id: 70,
    date: '2022-08-14',
    previsto: 254,
    realizado: 105,
  },
  {
    id: 71,
    date: '2022-08-15',
    previsto: 2,
    realizado: 24,
  },
  {
    id: 72,
    date: '2022-08-16',
    previsto: 13,
    realizado: 27,
  },
  {
    id: 73,
    date: '2022-08-17',
    previsto: 24,
    realizado: 31,
  },
  {
    id: 74,
    date: '2022-08-19',
    previsto: 9,
    realizado: 89,
  },
  {
    id: 75,
    date: '2022-08-20',
    previsto: 20,
    realizado: 114,
  },
  {
    id: 76,
    date: '2022-08-22',
    previsto: 7,
    realizado: 25,
  },
  {
    id: 77,
    date: '2022-08-23',
    previsto: 5,
    realizado: 27,
  },
  {
    id: 78,
    date: '2022-08-24',
    previsto: 7,
    realizado: 31,
  },
  {
    id: 79,
    date: '2022-08-25',
    previsto: 2,
    realizado: 49,
  },
  {
    id: 80,
    date: '2022-08-27',
    previsto: 47,
    realizado: 114,
  },
  {
    id: 81,
    date: '2022-08-28',
    previsto: 87,
    realizado: 62,
  },
  {
    id: 82,
    date: '2022-08-29',
    previsto: 12,
    realizado: 25,
  },
  {
    id: 83,
    date: '2022-08-30',
    previsto: 41,
    realizado: 27,
  },
  {
    id: 84,
    date: '2022-08-31',
    previsto: 48,
    realizado: 31,
  },
  {
    id: 85,
    date: '2022-09-02',
    previsto: 62,
    realizado: 56,
  },
  {
    id: 86,
    date: '2022-09-03',
    previsto: 101,
    realizado: 72,
  },
  {
    id: 87,
    date: '2022-09-04',
    previsto: 8,
    realizado: 36,
  },
  {
    id: 88,
    date: '2022-09-05',
    previsto: 0,
    realizado: 23,
  },
  {
    id: 89,
    date: '2022-09-06',
    previsto: 24,
    realizado: 15,
  },
  {
    id: 90,
    date: '2022-09-07',
    previsto: 88,
    realizado: 18,
  },
  {
    id: 91,
    date: '2022-09-08',
    previsto: 13,
    realizado: 38,
  },
  {
    id: 92,
    date: '2022-09-09',
    previsto: 114,
    realizado: 90,
  },
  {
    id: 93,
    date: '2022-09-10',
    previsto: 118,
    realizado: 115,
  },
  {
    id: 94,
    date: '2022-09-11',
    previsto: 92,
    realizado: 66,
  },
  {
    id: 95,
    date: '2022-09-12',
    previsto: 4,
    realizado: 10,
  },
  {
    id: 96,
    date: '2022-09-13',
    previsto: 16,
    realizado: 17,
  },
  {
    id: 97,
    date: '2022-09-15',
    previsto: 30,
    realizado: 29,
  },
  {
    id: 98,
    date: '2022-09-17',
    previsto: 221,
    realizado: 115,
  },
  {
    id: 99,
    date: '2022-09-18',
    previsto: 95,
    realizado: 63,
  },
  {
    id: 100,
    date: '2022-09-20',
    previsto: 57,
    realizado: 27,
  },
  {
    id: 101,
    date: '2022-09-21',
    previsto: 50,
    realizado: 20,
  },
  {
    id: 102,
    date: '2022-09-23',
    previsto: 33,
    realizado: 51,
  },
  {
    id: 103,
    date: '2022-09-25',
    previsto: 117,
    realizado: 39,
  },
  {
    id: 104,
    date: '2022-09-26',
    previsto: 11,
    realizado: 25,
  },
  {
    id: 105,
    date: '2022-09-29',
    previsto: 5,
    realizado: 38,
  },
  {
    id: 106,
    date: '2022-09-30',
    previsto: 43,
    realizado: 90,
  },
]

// ############################# MATERIAL 3 ################################################

export const MOCK_PREDICTED_GRAPH_3 = [
  {
    id: 1,
    date: '2022-06-01',
    previsto: 40,
    realizado: 18,
  },
  {
    id: 2,
    date: '2022-06-02',
    previsto: 8,
    realizado: 23,
  },
  {
    id: 3,
    date: '2022-06-03',
    previsto: 45,
    realizado: 34,
  },
  {
    id: 4,
    date: '2022-06-04',
    previsto: 53,
    realizado: 45,
  },
  {
    id: 5,
    date: '2022-06-05',
    previsto: 19,
    realizado: 29,
  },
  {
    id: 6,
    date: '2022-06-06',
    previsto: 0,
    realizado: 15,
  },
  {
    id: 7,
    date: '2022-06-07',
    previsto: 17,
    realizado: 17,
  },
  {
    id: 8,
    date: '2022-06-08',
    previsto: 24,
    realizado: 18,
  },
  {
    id: 9,
    date: '2022-06-09',
    previsto: 29,
    realizado: 24,
  },
  {
    id: 10,
    date: '2022-06-10',
    previsto: 62,
    realizado: 34,
  },
  {
    id: 11,
    date: '2022-06-11',
    previsto: 49,
    realizado: 45,
  },
  {
    id: 12,
    date: '2022-06-12',
    previsto: 18,
    realizado: 31,
  },
  {
    id: 13,
    date: '2022-06-13',
    previsto: 16,
    realizado: 12,
  },
  {
    id: 14,
    date: '2022-06-14',
    previsto: 7,
    realizado: 16,
  },
  {
    id: 15,
    date: '2022-06-15',
    previsto: 30,
    realizado: 18,
  },
  {
    id: 16,
    date: '2022-06-16',
    previsto: 37,
    realizado: 23,
  },
  {
    id: 17,
    date: '2022-06-17',
    previsto: 24,
    realizado: 34,
  },
  {
    id: 18,
    date: '2022-06-18',
    previsto: 56,
    realizado: 45,
  },
  {
    id: 19,
    date: '2022-06-19',
    previsto: 23,
    realizado: 29,
  },
  {
    id: 20,
    date: '2022-06-20',
    previsto: 19,
    realizado: 13,
  },
  {
    id: 21,
    date: '2022-06-21',
    previsto: 20,
    realizado: 17,
  },
  {
    id: 22,
    date: '2022-06-22',
    previsto: 25,
    realizado: 17,
  },
  {
    id: 23,
    date: '2022-06-23',
    previsto: 61,
    realizado: 20,
  },
  {
    id: 24,
    date: '2022-06-24',
    previsto: 63,
    realizado: 28,
  },
  {
    id: 25,
    date: '2022-06-25',
    previsto: 26,
    realizado: 40,
  },
  {
    id: 26,
    date: '2022-06-26',
    previsto: 12,
    realizado: 25,
  },
  {
    id: 27,
    date: '2022-06-27',
    previsto: 0,
    realizado: 15,
  },
  {
    id: 28,
    date: '2022-06-28',
    previsto: 7,
    realizado: 15,
  },
  {
    id: 29,
    date: '2022-06-29',
    previsto: 10,
    realizado: 16,
  },
  {
    id: 30,
    date: '2022-06-30',
    previsto: 11,
    realizado: 20,
  },
  {
    id: 31,
    date: '2022-07-01',
    previsto: 26,
    realizado: 34,
  },
  {
    id: 32,
    date: '2022-07-02',
    previsto: 24,
    realizado: 45,
  },
  {
    id: 33,
    date: '2022-07-03',
    previsto: 14,
    realizado: 31,
  },
  {
    id: 34,
    date: '2022-07-04',
    previsto: 12,
    realizado: 13,
  },
  {
    id: 35,
    date: '2022-07-05',
    previsto: 22,
    realizado: 17,
  },
  {
    id: 36,
    date: '2022-07-06',
    previsto: 14,
    realizado: 19,
  },
  {
    id: 37,
    date: '2022-07-07',
    previsto: 20,
    realizado: 22,
  },
  {
    id: 38,
    date: '2022-07-08',
    previsto: 34,
    realizado: 34,
  },
  {
    id: 39,
    date: '2022-07-09',
    previsto: 30,
    realizado: 45,
  },
  {
    id: 40,
    date: '2022-07-10',
    previsto: 13,
    realizado: 31,
  },
  {
    id: 41,
    date: '2022-07-11',
    previsto: 25,
    realizado: 14,
  },
  {
    id: 42,
    date: '2022-07-12',
    previsto: 22,
    realizado: 17,
  },
  {
    id: 43,
    date: '2022-07-13',
    previsto: 49,
    realizado: 19,
  },
  {
    id: 44,
    date: '2022-07-14',
    previsto: 36,
    realizado: 22,
  },
  {
    id: 45,
    date: '2022-07-18',
    previsto: 3,
    realizado: 13,
  },
  {
    id: 46,
    date: '2022-07-19',
    previsto: 8,
    realizado: 17,
  },
  {
    id: 47,
    date: '2022-07-20',
    previsto: 39,
    realizado: 18,
  },
  {
    id: 48,
    date: '2022-07-21',
    previsto: 68,
    realizado: 22,
  },
  {
    id: 49,
    date: '2022-07-22',
    previsto: 57,
    realizado: 31,
  },
  {
    id: 50,
    date: '2022-07-23',
    previsto: 80,
    realizado: 44,
  },
  {
    id: 51,
    date: '2022-07-24',
    previsto: 41,
    realizado: 31,
  },
  {
    id: 52,
    date: '2022-07-25',
    previsto: 16,
    realizado: 12,
  },
  {
    id: 53,
    date: '2022-07-26',
    previsto: 12,
    realizado: 19,
  },
  {
    id: 54,
    date: '2022-07-27',
    previsto: 3,
    realizado: 19,
  },
  {
    id: 55,
    date: '2022-07-28',
    previsto: 34,
    realizado: 22,
  },
  {
    id: 56,
    date: '2022-07-29',
    previsto: 24,
    realizado: 32,
  },
  {
    id: 57,
    date: '2022-07-30',
    previsto: 35,
    realizado: 42,
  },
  {
    id: 58,
    date: '2022-07-31',
    previsto: 12,
    realizado: 29,
  },
  {
    id: 59,
    date: '2022-08-02',
    previsto: 18,
    realizado: 14,
  },
  {
    id: 60,
    date: '2022-08-03',
    previsto: 9,
    realizado: 14,
  },
  {
    id: 61,
    date: '2022-08-04',
    previsto: 23,
    realizado: 18,
  },
  {
    id: 62,
    date: '2022-08-05',
    previsto: 12,
    realizado: 28,
  },
  {
    id: 63,
    date: '2022-08-06',
    previsto: 38,
    realizado: 36,
  },
  {
    id: 64,
    date: '2022-08-07',
    previsto: 34,
    realizado: 27,
  },
  {
    id: 65,
    date: '2022-08-08',
    previsto: 13,
    realizado: 11,
  },
  {
    id: 66,
    date: '2022-08-09',
    previsto: 114,
    realizado: 15,
  },
  {
    id: 67,
    date: '2022-08-10',
    previsto: 28,
    realizado: 17,
  },
  {
    id: 68,
    date: '2022-08-12',
    previsto: 57,
    realizado: 27,
  },
  {
    id: 69,
    date: '2022-08-13',
    previsto: 57,
    realizado: 39,
  },
  {
    id: 70,
    date: '2022-08-14',
    previsto: 56,
    realizado: 25,
  },
  {
    id: 71,
    date: '2022-08-15',
    previsto: 9,
    realizado: 11,
  },
  {
    id: 72,
    date: '2022-08-16',
    previsto: 7,
    realizado: 15,
  },
  {
    id: 73,
    date: '2022-08-17',
    previsto: 25,
    realizado: 15,
  },
  {
    id: 74,
    date: '2022-08-19',
    previsto: 45,
    realizado: 30,
  },
  {
    id: 75,
    date: '2022-08-20',
    previsto: 12,
    realizado: 36,
  },
  {
    id: 76,
    date: '2022-08-22',
    previsto: 7,
    realizado: 10,
  },
  {
    id: 77,
    date: '2022-08-23',
    previsto: 31,
    realizado: 14,
  },
  {
    id: 78,
    date: '2022-08-24',
    previsto: 5,
    realizado: 15,
  },
  {
    id: 79,
    date: '2022-08-25',
    previsto: 16,
    realizado: 19,
  },
  {
    id: 80,
    date: '2022-08-27',
    previsto: 34,
    realizado: 36,
  },
  {
    id: 81,
    date: '2022-08-28',
    previsto: 21,
    realizado: 27,
  },
  {
    id: 82,
    date: '2022-08-29',
    previsto: 6,
    realizado: 11,
  },
  {
    id: 83,
    date: '2022-08-30',
    previsto: 11,
    realizado: 15,
  },
  {
    id: 84,
    date: '2022-08-31',
    previsto: 4,
    realizado: 17,
  },
  {
    id: 85,
    date: '2022-09-02',
    previsto: 36,
    realizado: 33,
  },
  {
    id: 86,
    date: '2022-09-03',
    previsto: 39,
    realizado: 42,
  },
  {
    id: 87,
    date: '2022-09-04',
    previsto: 21,
    realizado: 28,
  },
  {
    id: 88,
    date: '2022-09-05',
    previsto: 13,
    realizado: 12,
  },
  {
    id: 89,
    date: '2022-09-06',
    previsto: 42,
    realizado: 18,
  },
  {
    id: 90,
    date: '2022-09-07',
    previsto: 39,
    realizado: 17,
  },
  {
    id: 91,
    date: '2022-09-08',
    previsto: 8,
    realizado: 22,
  },
  {
    id: 92,
    date: '2022-09-09',
    previsto: 66,
    realizado: 33,
  },
  {
    id: 93,
    date: '2022-09-10',
    previsto: 38,
    realizado: 44,
  },
  {
    id: 94,
    date: '2022-09-11',
    previsto: 30,
    realizado: 39,
  },
  {
    id: 95,
    date: '2022-09-12',
    previsto: 2,
    realizado: 12,
  },
  {
    id: 96,
    date: '2022-09-13',
    previsto: 15,
    realizado: 17,
  },
  {
    id: 97,
    date: '2022-09-15',
    previsto: 23,
    realizado: 22,
  },
  {
    id: 98,
    date: '2022-09-17',
    previsto: 18,
    realizado: 44,
  },
  {
    id: 99,
    date: '2022-09-18',
    previsto: 1,
    realizado: 28,
  },
  {
    id: 100,
    date: '2022-09-20',
    previsto: 5,
    realizado: 17,
  },
  {
    id: 101,
    date: '2022-09-21',
    previsto: 1,
    realizado: 17,
  },
  {
    id: 102,
    date: '2022-09-23',
    previsto: 21,
    realizado: 33,
  },
  {
    id: 103,
    date: '2022-09-25',
    previsto: 44,
    realizado: 28,
  },
  {
    id: 104,
    date: '2022-09-26',
    previsto: 18,
    realizado: 12,
  },
  {
    id: 105,
    date: '2022-09-29',
    previsto: 5,
    realizado: 55,
  },
  {
    id: 106,
    date: '2022-09-30',
    previsto: 76,
    realizado: 51,
  },
]

// ##################### MATERIAL 4 #####################################

export const MOCK_PREDICTED_GRAPH_4 = [
  {
    id: 1,
    date: '2022-06-01',
    previsto: 1,
    realizado: 10,
  },
  {
    id: 2,
    date: '2022-06-02',
    previsto: 4,
    realizado: 24,
  },
  {
    id: 3,
    date: '2022-06-03',
    previsto: 1,
    realizado: 2,
  },
  {
    id: 4,
    date: '2022-06-04',
    previsto: 6,
    realizado: 9,
  },
  {
    id: 5,
    date: '2022-06-05',
    previsto: 3,
    realizado: 0,
  },
  {
    id: 6,
    date: '2022-06-06',
    previsto: 8,
    realizado: 13,
  },
  {
    id: 7,
    date: '2022-06-07',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 8,
    date: '2022-06-08',
    previsto: 8,
    realizado: 10,
  },
  {
    id: 9,
    date: '2022-06-09',
    previsto: 1,
    realizado: 8,
  },
  {
    id: 10,
    date: '2022-06-10',
    previsto: 6,
    realizado: 2,
  },
  {
    id: 11,
    date: '2022-06-11',
    previsto: 16,
    realizado: 9,
  },
  {
    id: 12,
    date: '2022-06-12',
    previsto: 1,
    realizado: 0,
  },
  {
    id: 13,
    date: '2022-06-13',
    previsto: 0,
    realizado: 12,
  },
  {
    id: 14,
    date: '2022-06-14',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 15,
    date: '2022-06-15',
    previsto: 5,
    realizado: 10,
  },
  {
    id: 16,
    date: '2022-06-16',
    previsto: 4,
    realizado: 8,
  },
  {
    id: 17,
    date: '2022-06-17',
    previsto: 2,
    realizado: 2,
  },
  {
    id: 18,
    date: '2022-06-18',
    previsto: 4,
    realizado: 9,
  },
  {
    id: 19,
    date: '2022-06-19',
    previsto: 0,
    realizado: 0,
  },
  {
    id: 20,
    date: '2022-06-20',
    previsto: 1,
    realizado: 11,
  },
  {
    id: 21,
    date: '2022-06-21',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 22,
    date: '2022-06-22',
    previsto: 5,
    realizado: 10,
  },
  {
    id: 23,
    date: '2022-06-23',
    previsto: 0,
    realizado: 10,
  },
  {
    id: 24,
    date: '2022-06-24',
    previsto: 1,
    realizado: 2,
  },
  {
    id: 25,
    date: '2022-06-25',
    previsto: 8,
    realizado: 9,
  },
  {
    id: 26,
    date: '2022-06-26',
    previsto: 1,
    realizado: 0,
  },
  {
    id: 27,
    date: '2022-06-27',
    previsto: 0,
    realizado: 12,
  },
  {
    id: 28,
    date: '2022-06-28',
    previsto: 1,
    realizado: 6,
  },
  {
    id: 29,
    date: '2022-06-29',
    previsto: 2,
    realizado: 10,
  },
  {
    id: 30,
    date: '2022-06-30',
    previsto: 1,
    realizado: 8,
  },
  {
    id: 31,
    date: '2022-07-01',
    previsto: 2,
    realizado: 0,
  },
  {
    id: 32,
    date: '2022-07-02',
    previsto: 4,
    realizado: 2,
  },
  {
    id: 33,
    date: '2022-07-03',
    previsto: 0,
    realizado: 0,
  },
  {
    id: 34,
    date: '2022-07-04',
    previsto: 1,
    realizado: 3,
  },
  {
    id: 35,
    date: '2022-07-05',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 36,
    date: '2022-07-06',
    previsto: 0,
    realizado: 2,
  },
  {
    id: 37,
    date: '2022-07-07',
    previsto: 6,
    realizado: 2,
  },
  {
    id: 38,
    date: '2022-07-08',
    previsto: 5,
    realizado: 0,
  },
  {
    id: 39,
    date: '2022-07-09',
    previsto: 5,
    realizado: 2,
  },
  {
    id: 40,
    date: '2022-07-10',
    previsto: 7,
    realizado: 0,
  },
  {
    id: 41,
    date: '2022-07-11',
    previsto: 0,
    realizado: 3,
  },
  {
    id: 42,
    date: '2022-07-12',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 43,
    date: '2022-07-13',
    previsto: 1,
    realizado: 2,
  },
  {
    id: 44,
    date: '2022-07-14',
    previsto: 0,
    realizado: 2,
  },
  {
    id: 45,
    date: '2022-07-18',
    previsto: 0,
    realizado: 3,
  },
  {
    id: 46,
    date: '2022-07-19',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 47,
    date: '2022-07-20',
    previsto: 8,
    realizado: 2,
  },
  {
    id: 48,
    date: '2022-07-21',
    previsto: 3,
    realizado: 2,
  },
  {
    id: 49,
    date: '2022-07-22',
    previsto: 11,
    realizado: 0,
  },
  {
    id: 50,
    date: '2022-07-23',
    previsto: 9,
    realizado: 2,
  },
  {
    id: 51,
    date: '2022-07-24',
    previsto: 4,
    realizado: 0,
  },
  {
    id: 52,
    date: '2022-07-25',
    previsto: 0,
    realizado: 3,
  },
  {
    id: 53,
    date: '2022-07-26',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 54,
    date: '2022-07-27',
    previsto: 1,
    realizado: 2,
  },
  {
    id: 55,
    date: '2022-07-28',
    previsto: 2,
    realizado: 2,
  },
  {
    id: 56,
    date: '2022-07-29',
    previsto: 9,
    realizado: 0,
  },
  {
    id: 57,
    date: '2022-07-30',
    previsto: 7,
    realizado: 2,
  },
  {
    id: 58,
    date: '2022-07-31',
    previsto: 0,
    realizado: 0,
  },
  {
    id: 59,
    date: '2022-08-02',
    previsto: 8,
    realizado: 33,
  },
  {
    id: 60,
    date: '2022-08-03',
    previsto: 4,
    realizado: 44,
  },
  {
    id: 61,
    date: '2022-08-04',
    previsto: 0,
    realizado: 52,
  },
  {
    id: 62,
    date: '2022-08-05',
    previsto: 1,
    realizado: 7,
  },
  {
    id: 63,
    date: '2022-08-06',
    previsto: 5,
    realizado: 35,
  },
  {
    id: 64,
    date: '2022-08-07',
    previsto: 2,
    realizado: 1,
  },
  {
    id: 65,
    date: '2022-08-08',
    previsto: 1,
    realizado: 46,
  },
  {
    id: 66,
    date: '2022-08-09',
    previsto: 1,
    realizado: 22,
  },
  {
    id: 67,
    date: '2022-08-10',
    previsto: 0,
    realizado: 60,
  },
  {
    id: 68,
    date: '2022-08-12',
    previsto: 1,
    realizado: 7,
  },
  {
    id: 69,
    date: '2022-08-13',
    previsto: 1,
    realizado: 35,
  },
  {
    id: 70,
    date: '2022-08-14',
    previsto: 3,
    realizado: 1,
  },
  {
    id: 71,
    date: '2022-08-15',
    previsto: 1,
    realizado: 46,
  },
  {
    id: 72,
    date: '2022-08-16',
    previsto: 1,
    realizado: 22,
  },
  {
    id: 73,
    date: '2022-08-17',
    previsto: 2,
    realizado: 44,
  },
  {
    id: 74,
    date: '2022-08-19',
    previsto: 4,
    realizado: 7,
  },
  {
    id: 75,
    date: '2022-08-20',
    previsto: 4,
    realizado: 35,
  },
  {
    id: 76,
    date: '2022-08-22',
    previsto: 0,
    realizado: 64,
  },
  {
    id: 77,
    date: '2022-08-23',
    previsto: 0,
    realizado: 31,
  },
  {
    id: 78,
    date: '2022-08-24',
    previsto: 2,
    realizado: 44,
  },
  {
    id: 79,
    date: '2022-08-25',
    previsto: 3,
    realizado: 38,
  },
  {
    id: 80,
    date: '2022-08-27',
    previsto: 3,
    realizado: 35,
  },
  {
    id: 81,
    date: '2022-08-28',
    previsto: 0,
    realizado: 1,
  },
  {
    id: 82,
    date: '2022-08-29',
    previsto: 1,
    realizado: 46,
  },
  {
    id: 83,
    date: '2022-08-30',
    previsto: 2,
    realizado: 22,
  },
  {
    id: 84,
    date: '2022-08-31',
    previsto: 5,
    realizado: 115,
  },
  {
    id: 85,
    date: '2022-09-02',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 86,
    date: '2022-09-03',
    previsto: 1,
    realizado: 7,
  },
  {
    id: 87,
    date: '2022-09-04',
    previsto: 0,
    realizado: 0,
  },
  {
    id: 88,
    date: '2022-09-05',
    previsto: 2,
    realizado: 9,
  },
  {
    id: 89,
    date: '2022-09-06',
    previsto: 5,
    realizado: 4,
  },
  {
    id: 90,
    date: '2022-09-07',
    previsto: 0,
    realizado: 12,
  },
  {
    id: 91,
    date: '2022-09-08',
    previsto: 0,
    realizado: 10,
  },
  {
    id: 92,
    date: '2022-09-09',
    previsto: 1,
    realizado: 1,
  },
  {
    id: 93,
    date: '2022-09-10',
    previsto: 4,
    realizado: 7,
  },
  {
    id: 94,
    date: '2022-09-11',
    previsto: 5,
    realizado: 0,
  },
  {
    id: 95,
    date: '2022-09-12',
    previsto: 2,
    realizado: 9,
  },
  {
    id: 96,
    date: '2022-09-13',
    previsto: 1,
    realizado: 4,
  },
  {
    id: 97,
    date: '2022-09-15',
    previsto: 7,
    realizado: 7,
  },
  {
    id: 98,
    date: '2022-09-17',
    previsto: 5,
    realizado: 7,
  },
  {
    id: 99,
    date: '2022-09-18',
    previsto: 3,
    realizado: 0,
  },
  {
    id: 100,
    date: '2022-09-20',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 101,
    date: '2022-09-21',
    previsto: 1,
    realizado: 8,
  },
  {
    id: 102,
    date: '2022-09-23',
    previsto: 4,
    realizado: 1,
  },
  {
    id: 103,
    date: '2022-09-25',
    previsto: 2,
    realizado: 0,
  },
  {
    id: 104,
    date: '2022-09-26',
    previsto: 1,
    realizado: 9,
  },
  {
    id: 105,
    date: '2022-09-29',
    previsto: 5,
    realizado: 19,
  },
  {
    id: 106,
    date: '2022-09-30',
    previsto: 1,
    realizado: 1,
  },
]

// ###################### MATERIAL 5 ##########################

export const MOCK_PREDICTED_GRAPH_5 = [
  {
    id: 107,
    date: '2022-06-01',
    previsto: 4,
    realizado: 5,
  },
  {
    id: 108,
    date: '2022-06-02',
    previsto: 16,
    realizado: 12,
  },
  {
    id: 109,
    date: '2022-06-03',
    previsto: 3,
    realizado: 20,
  },
  {
    id: 110,
    date: '2022-06-04',
    previsto: 68,
    realizado: 20,
  },
  {
    id: 111,
    date: '2022-06-05',
    previsto: 11,
    realizado: 9,
  },
  {
    id: 112,
    date: '2022-06-06',
    previsto: 16,
    realizado: 7,
  },
  {
    id: 113,
    date: '2022-06-07',
    previsto: 0,
    realizado: 7,
  },
  {
    id: 114,
    date: '2022-06-08',
    previsto: 2,
    realizado: 5,
  },
  {
    id: 115,
    date: '2022-06-09',
    previsto: 10,
    realizado: 10,
  },
  {
    id: 116,
    date: '2022-06-10',
    previsto: 25,
    realizado: 18,
  },
  {
    id: 117,
    date: '2022-06-11',
    previsto: 37,
    realizado: 20,
  },
  {
    id: 118,
    date: '2022-06-12',
    previsto: 11,
    realizado: 9,
  },
  {
    id: 119,
    date: '2022-06-13',
    previsto: 6,
    realizado: 6,
  },
  {
    id: 120,
    date: '2022-06-14',
    previsto: 0,
    realizado: 5,
  },
  {
    id: 121,
    date: '2022-06-15',
    previsto: 0,
    realizado: 5,
  },
  {
    id: 122,
    date: '2022-06-16',
    previsto: 18,
    realizado: 11,
  },
  {
    id: 123,
    date: '2022-06-17',
    previsto: 14,
    realizado: 18,
  },
  {
    id: 124,
    date: '2022-06-18',
    previsto: 7,
    realizado: 20,
  },
  {
    id: 125,
    date: '2022-06-19',
    previsto: 0,
    realizado: 11,
  },
  {
    id: 126,
    date: '2022-06-20',
    previsto: 6,
    realizado: 6,
  },
  {
    id: 127,
    date: '2022-06-21',
    previsto: 8,
    realizado: 6,
  },
  {
    id: 128,
    date: '2022-06-22',
    previsto: 4,
    realizado: 3,
  },
  {
    id: 129,
    date: '2022-06-23',
    previsto: 22,
    realizado: 12,
  },
  {
    id: 130,
    date: '2022-06-24',
    previsto: 44,
    realizado: 18,
  },
  {
    id: 131,
    date: '2022-06-25',
    previsto: 14,
    realizado: 20,
  },
  {
    id: 132,
    date: '2022-06-26',
    previsto: 9,
    realizado: 9,
  },
  {
    id: 133,
    date: '2022-06-27',
    previsto: 0,
    realizado: 7,
  },
  {
    id: 134,
    date: '2022-06-28',
    previsto: 12,
    realizado: 5,
  },
  {
    id: 135,
    date: '2022-06-29',
    previsto: 3,
    realizado: 6,
  },
  {
    id: 136,
    date: '2022-06-30',
    previsto: 0,
    realizado: 14,
  },
  {
    id: 137,
    date: '2022-07-01',
    previsto: 0,
    realizado: 25,
  },
  {
    id: 138,
    date: '2022-07-02',
    previsto: 34,
    realizado: 23,
  },
  {
    id: 139,
    date: '2022-07-03',
    previsto: 8,
    realizado: 11,
  },
  {
    id: 140,
    date: '2022-07-04',
    previsto: 0,
    realizado: 11,
  },
  {
    id: 141,
    date: '2022-07-05',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 142,
    date: '2022-07-06',
    previsto: 10,
    realizado: 4,
  },
  {
    id: 143,
    date: '2022-07-07',
    previsto: 22,
    realizado: 19,
  },
  {
    id: 144,
    date: '2022-07-08',
    previsto: 14,
    realizado: 20,
  },
  {
    id: 145,
    date: '2022-07-09',
    previsto: 45,
    realizado: 23,
  },
  {
    id: 146,
    date: '2022-07-10',
    previsto: 2,
    realizado: 10,
  },
  {
    id: 147,
    date: '2022-07-11',
    previsto: 1,
    realizado: 9,
  },
  {
    id: 148,
    date: '2022-07-12',
    previsto: 2,
    realizado: 7,
  },
  {
    id: 149,
    date: '2022-07-13',
    previsto: 4,
    realizado: 4,
  },
  {
    id: 150,
    date: '2022-07-14',
    previsto: 1,
    realizado: 13,
  },
  {
    id: 151,
    date: '2022-07-18',
    previsto: 8,
    realizado: 7,
  },
  {
    id: 152,
    date: '2022-07-19',
    previsto: 2,
    realizado: 5,
  },
  {
    id: 153,
    date: '2022-07-20',
    previsto: 0,
    realizado: 5,
  },
  {
    id: 154,
    date: '2022-07-21',
    previsto: 16,
    realizado: 13,
  },
  {
    id: 155,
    date: '2022-07-22',
    previsto: 52,
    realizado: 21,
  },
  {
    id: 156,
    date: '2022-07-23',
    previsto: 19,
    realizado: 25,
  },
  {
    id: 157,
    date: '2022-07-24',
    previsto: 4,
    realizado: 11,
  },
  {
    id: 158,
    date: '2022-07-25',
    previsto: 72,
    realizado: 10,
  },
  {
    id: 159,
    date: '2022-07-26',
    previsto: 0,
    realizado: 8,
  },
  {
    id: 160,
    date: '2022-07-27',
    previsto: 12,
    realizado: 4,
  },
  {
    id: 161,
    date: '2022-07-28',
    previsto: 14,
    realizado: 19,
  },
  {
    id: 162,
    date: '2022-07-29',
    previsto: 5,
    realizado: 29,
  },
  {
    id: 163,
    date: '2022-07-30',
    previsto: 23,
    realizado: 23,
  },
  {
    id: 164,
    date: '2022-07-31',
    previsto: 7,
    realizado: 15,
  },
  {
    id: 165,
    date: '2022-08-02',
    previsto: 4,
    realizado: 7,
  },
  {
    id: 166,
    date: '2022-08-03',
    previsto: 8,
    realizado: 4,
  },
  {
    id: 167,
    date: '2022-08-04',
    previsto: 0,
    realizado: 16,
  },
  {
    id: 168,
    date: '2022-08-05',
    previsto: 0,
    realizado: 23,
  },
  {
    id: 169,
    date: '2022-08-06',
    previsto: 0,
    realizado: 34,
  },
  {
    id: 170,
    date: '2022-08-07',
    previsto: 0,
    realizado: 16,
  },
  {
    id: 171,
    date: '2022-08-08',
    previsto: 0,
    realizado: 10,
  },
  {
    id: 172,
    date: '2022-08-09',
    previsto: 14,
    realizado: 7,
  },
  {
    id: 173,
    date: '2022-08-10',
    previsto: 0,
    realizado: 6,
  },
  {
    id: 174,
    date: '2022-08-12',
    previsto: 6,
    realizado: 27,
  },
  {
    id: 175,
    date: '2022-08-13',
    previsto: 66,
    realizado: 30,
  },
  {
    id: 176,
    date: '2022-08-14',
    previsto: 14,
    realizado: 15,
  },
  {
    id: 177,
    date: '2022-08-15',
    previsto: 15,
    realizado: 8,
  },
  {
    id: 178,
    date: '2022-08-16',
    previsto: 13,
    realizado: 7,
  },
  {
    id: 179,
    date: '2022-08-17',
    previsto: 9,
    realizado: 6,
  },
  {
    id: 180,
    date: '2022-08-19',
    previsto: 18,
    realizado: 27,
  },
  {
    id: 181,
    date: '2022-08-20',
    previsto: 66,
    realizado: 32,
  },
  {
    id: 182,
    date: '2022-08-22',
    previsto: 6,
    realizado: 8,
  },
  {
    id: 183,
    date: '2022-08-23',
    previsto: 4,
    realizado: 7,
  },
  {
    id: 184,
    date: '2022-08-24',
    previsto: 4,
    realizado: 6,
  },
  {
    id: 185,
    date: '2022-08-25',
    previsto: 17,
    realizado: 32,
  },
  {
    id: 186,
    date: '2022-08-27',
    previsto: 40,
    realizado: 58,
  },
  {
    id: 187,
    date: '2022-08-28',
    previsto: 12,
    realizado: 62,
  },
  {
    id: 188,
    date: '2022-08-29',
    previsto: 16,
    realizado: 8,
  },
  {
    id: 189,
    date: '2022-08-30',
    previsto: 0,
    realizado: 7,
  },
  {
    id: 190,
    date: '2022-08-31',
    previsto: 13,
    realizado: 6,
  },
  {
    id: 191,
    date: '2022-09-02',
    previsto: 11,
    realizado: 10,
  },
  {
    id: 192,
    date: '2022-09-03',
    previsto: 29,
    realizado: 11,
  },
  {
    id: 193,
    date: '2022-09-04',
    previsto: 0,
    realizado: 4,
  },
  {
    id: 194,
    date: '2022-09-05',
    previsto: 2,
    realizado: 2,
  },
  {
    id: 195,
    date: '2022-09-06',
    previsto: 7,
    realizado: 2,
  },
  {
    id: 196,
    date: '2022-09-07',
    previsto: 6,
    realizado: 1,
  },
  {
    id: 197,
    date: '2022-09-08',
    previsto: 12,
    realizado: 5,
  },
  {
    id: 198,
    date: '2022-09-09',
    previsto: 36,
    realizado: 7,
  },
  {
    id: 199,
    date: '2022-09-10',
    previsto: 26,
    realizado: 8,
  },
  {
    id: 200,
    date: '2022-09-11',
    previsto: 3,
    realizado: 5,
  },
  {
    id: 201,
    date: '2022-09-12',
    previsto: 6,
    realizado: 2,
  },
  {
    id: 202,
    date: '2022-09-13',
    previsto: 7,
    realizado: 3,
  },
  {
    id: 203,
    date: '2022-09-15',
    previsto: 8,
    realizado: 5,
  },
  {
    id: 204,
    date: '2022-09-17',
    previsto: 3,
    realizado: 8,
  },
  {
    id: 205,
    date: '2022-09-18',
    previsto: 4,
    realizado: 3,
  },
  {
    id: 206,
    date: '2022-09-20',
    previsto: 0,
    realizado: 2,
  },
  {
    id: 207,
    date: '2022-09-21',
    previsto: 0,
    realizado: 2,
  },
  {
    id: 208,
    date: '2022-09-23',
    previsto: 4,
    realizado: 7,
  },
  {
    id: 209,
    date: '2022-09-25',
    previsto: 0,
    realizado: 4,
  },
  {
    id: 210,
    date: '2022-09-26',
    previsto: 0,
    realizado: 3,
  },
  {
    id: 211,
    date: '2022-09-29',
    previsto: 5,
    realizado: 7,
  },
  {
    id: 212,
    date: '2022-09-30',
    previsto: 0,
    realizado: 9,
  },
]

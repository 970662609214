import { memo, useMemo } from 'react'
import { Td } from '@chakra-ui/react'

import StickyCell from './StickyCell'

interface ICellProps {
  children: Children
  colIndex: number
  rowIndex?: number
  cellsRef: React.MutableRefObject<any[]>
  stickyPos: number
  customStyle: { [key: string]: any }
}

const Cell: React.FC<ICellProps> = ({
  children,
  colIndex,
  rowIndex,
  cellsRef,
  stickyPos,
  customStyle,
}) => {
  const sticky = useMemo(() => {
    return colIndex < stickyPos
  }, [colIndex, stickyPos])

  if (!sticky) return <Td {...customStyle}>{children}</Td>
  return (
    <StickyCell
      colIndex={colIndex}
      cellsRef={cellsRef}
      rowIndex={rowIndex}
      customStyle={customStyle}
    >
      {children}
    </StickyCell>
  )
}

export default memo(Cell)

import styled from 'styled-components'
import { Input } from '@chakra-ui/react'

export const Container = styled.div`
  width: 18rem;
`

export const Label = styled.p`
  margin-bottom: 0.5rem;
`

export const InputStyled = styled(Input)`
  font-size: 1rem !important;
  background: white !important;
`

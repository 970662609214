import { useCallback, useEffect, useRef, useState } from 'react'
import { FaUndoAlt as ResetSvg } from 'react-icons/fa'
import { Flex, Input, InputGroup, InputLeftAddon, Text } from '@chakra-ui/react'

import { moneyComma } from 'utils/masks'
import Button from 'components/Button'

interface IProps {
  price: string
  setPrice: React.Dispatch<React.SetStateAction<string>>
  getPrice: () => void
}

const PriceToPractice: React.FC<IProps> = ({ price, setPrice, getPrice }) => {
  const [value, setValue] = useState(price)
  const [focusValue, setFocusValue] = useState(price)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setValue(price)
    setFocusValue(price)
  }, [price])

  const onBlur = useCallback(() => {
    if (!+value?.replace(',', '.')) return setValue(focusValue)
    setPrice(value)
  }, [focusValue, setPrice, value])

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value, setValue])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(moneyComma(e.target.value))
    },
    [setValue]
  )

  const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current!.blur()
    }
  }, [])

  return (
    <Flex
      w="100%"
      p="1rem"
      align="center"
      justify="space-between"
      bgColor="#fff"
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.200"
    >
      <Text fontSize="sm">Preço a praticar m+1</Text>
      <Flex align="center">
        <Button
          onClick={getPrice}
          padding="0.5rem"
          containerStyle={{ height: '2rem', fontSize: 12 }}
        >
          {`Último Preço`} <ResetSvg style={{ marginLeft: '0.5rem' }} />
        </Button>
        <InputGroup ml="1rem" size="sm" maxW="10rem">
          <InputLeftAddon>R$</InputLeftAddon>
          <Input
            ref={inputRef}
            placeholder={focusValue}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            onKeyPress={onKeyPress}
            textAlign="right"
          />
        </InputGroup>
      </Flex>
    </Flex>
  )
}

export default PriceToPractice

import { useCallback, useState } from 'react'
import { Checkbox } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  updateCell: (accessor: string, newValue: any) => void
}

const Pricepoint: React.FC<IProps> = ({ row, updateCell }) => {
  const [value, setValue] = useState(row.is_pricepoint)

  const onChange = useCallback(() => {
    const newValue = !value

    setValue(newValue)
    updateCell('is_pricepoint', newValue)

    const url = '/politics/update'
    const req = {
      id: row.id,
      is_pricepoint: newValue,
    }

    api.post(url, req).catch(() => {
      setValue(!newValue)
      updateCell('is_pricepoint', !newValue)
    })
  }, [row.id, updateCell, value])

  return <Checkbox size="lg" isChecked={value} onChange={onChange} />
}

export default Pricepoint

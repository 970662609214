import { useCallback, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  param: string
  clusterId: string
  updateRow: (newRow: { [key: string]: any }) => void
  isPercent?: boolean
}

const Input: React.FC<IProps> = ({
  row,
  param,
  clusterId,
  updateRow,
  isPercent = false,
}) => {
  const [value, setValue] = useState(row[param])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const onBlur = useCallback(() => {
    const url = '/industry-margins'
    const req: { [key: string]: any } = {
      nivel01: row.nivel01,
      nivel02: row.nivel02,
      nivel03: row.nivel03,
      nivel04: row.nivel04,
      nivel05: row.nivel05,
      cluster_id: clusterId,
      [param]: +value,
    }

    api
      .put(url, req)
      .then((res) => {
        updateRow({ ...row, ...res.data.data.industry_margin })
      })
      .catch(() => {
        setValue(row[param])
      })
  }, [clusterId, param, row, updateRow, value])

  return (
    <InputGroup w="10rem">
      {isPercent && <InputLeftAddon>%</InputLeftAddon>}
      <ChakraInput
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </InputGroup>
  )
}

export default Input

import { useCallback, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: any
  captain: any
}

const InputRelativity: React.FC<IProps> = ({ captain, row }) => {
  const [relativity, setRelativity] = useState(row.relativity)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRelativity(e.target.value)
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const newVal = e.target.value || '0'
      setRelativity(newVal)

      const url = `/captains/${captain}/materials/${row.sku}`
      const req = { relativity: newVal }

      api.put(url, req).catch(() => {
        setRelativity(row.relativity)
      })
    },
    [captain, row.relativity, row.sku]
  )

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        maxW="7rem"
        type="number"
        name="relativity"
        value={relativity}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={6}
      />
    </InputGroup>
  )
}

export default InputRelativity

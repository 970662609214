import { useToast } from '@chakra-ui/react'
import LoadingScreen from 'components/LoadingScreen'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/api'

const AuthDemo: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const demoLogin = useCallback(() => {
    const request = {
      email: 'prixsia.demo.ind@pricemet.com.br',
      password: '@pricemet',
    }

    api
      .post('auth/login', request)
      .then((response) => {
        const { token, user } = response.data.data

        localStorage.setItem('@Prixsia:token', token)
        localStorage.setItem('@Prixsia:user', JSON.stringify(user))

        navigate('/dashboard')
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro login demo, tente novamente.',
            status: 'error',
          })
        }
        navigate('/login')
      })
  }, [navigate, toast])
  useEffect(demoLogin, [demoLogin])

  return <LoadingScreen />
}

export default AuthDemo

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${tooltipItem.dataset.label} ${tooltipItem.raw}%`
        },
      },
    },
    datalabels: { formatter: () => '' },
  },
  scales: {
    y: {
      ticks: { callback: (val: any) => val + '%' },
    },
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20,
      },
    },
  },
}

export const defaultData: any = {
  labels: [''],
  datasets: [
    {
      label: '',
      data: [0],
      backgroundColor: '',
      borderWidth: 0,
      maxBarThickness: 40,
    },
  ],
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

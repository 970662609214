import { useCallback, useState } from 'react'
import { Select } from '@chakra-ui/react'

import api from 'services/api'

import IRow from '../../types/IRow'

interface IProps {
  row: IRow
  channels: IOption[]
}

const Channel: React.FC<IProps> = ({ row, channels }) => {
  const [value, setValue] = useState(row.channel_id)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const url = '/politics/update'
      const req = {
        id: row.id,
        channel_id: +newVal,
      }

      api.post(url, req).catch(() => {
        setValue(row.channel_id)
      })
    },
    [row]
  )

  return (
    <Select w="11rem" value={value} onChange={onChange}>
      {channels.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default Channel

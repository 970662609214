import styled from 'styled-components'

export const Container = styled.div`
  div {
    color: #003b74;
    position: fixed;
    top: calc(50vh - 4.1rem);
    left: calc(50vw - 1.7rem);
  }
`

import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaEye as ViewSvg } from 'react-icons/fa'
import {
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Contract from '../Contract'

interface IProps {
  bundle: any
}

const ViewButton: React.FC<IProps> = ({ bundle }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [clients, setClients] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const clientTemplateColumns = useMemo(() => {
    return '0.1fr 0.25fr 1fr 0.25fr'
  }, [])

  const getClients = useCallback(() => {
    isOpen
      ? api.get(`/clusters/bundle/${bundle.id}/clients`).then((response) => {
          setClients(response.data.data.clients)
          setTimeout(() => setLoading(false), 1000)
        })
      : setLoading(true)
  }, [bundle, isOpen])

  useEffect(getClients, [getClients])

  return (
    <>
      <ViewSvg
        onClick={onOpen}
        size={18}
        style={{ cursor: 'pointer', color: '#003b74' }}
      />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="80vw">
          <ModalHeader>Editar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <HStack color="#003b74">
                <Text w="10rem">UF</Text>
                <Text w="10rem">Cidade</Text>
                <Text w="10rem">Canal</Text>
                <Text w="10rem">Contrato</Text>
              </HStack>
              <HStack color="#919191">
                <Text w="10rem">{bundle.uf_code}</Text>
                <Text w="10rem">{bundle.city}</Text>
                <Text w="10rem">{bundle.channel_name}</Text>
                <Contract bundle={bundle} />
              </HStack>
            </VStack>
            <Divider my={8} />
            {loading ? (
              <Flex my={4} justifyContent="center">
                <Spinner color="#003b74" />
              </Flex>
            ) : (
              <Flex flexDir="column">
                <Grid
                  color="#003b74"
                  w="100%"
                  mb={2}
                  gap={6}
                  templateColumns={clientTemplateColumns}
                >
                  <GridItem>UF</GridItem>
                  <GridItem>Cód</GridItem>
                  <GridItem>Clientes</GridItem>
                  <GridItem>Cidade</GridItem>
                </Grid>
                {clients.map((client) => (
                  <Grid
                    key={client.cliend_id}
                    w="100%"
                    gap={6}
                    py={1}
                    color="#919191"
                    templateColumns={clientTemplateColumns}
                    _hover={{ color: '#003b74' }}
                  >
                    <GridItem>{client.uf_code}</GridItem>
                    <GridItem>{client.client_id}</GridItem>
                    <GridItem>{client.client_name}</GridItem>
                    <GridItem>{client.city}</GridItem>
                  </Grid>
                ))}
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ViewButton

import { useCallback, useEffect, useState } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import api from 'services/api'
import Autocomplete from 'components/Autocomplete'

interface IProps {
  level: string
  setRequest: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

const Filters: React.FC<IProps> = ({ level, setRequest }) => {
  const [value, setValue] = useState<IOption[]>([])
  const [options, setOptions] = useState<IOption[]>([])

  const getOptions = useCallback(() => {
    setValue([])
    api.get(`/materials/levels?level=${level}`).then((res) => {
      setOptions(
        res.data.data[level].map((item: any) => ({
          value: item,
          label: item,
        }))
      )
    })
  }, [level])
  useEffect(getOptions, [getOptions])

  const updateRequest = useCallback(() => {
    const req = { [level]: value.map((item) => item.value) }
    setRequest(req)
  }, [level, setRequest, value])
  useEffect(updateRequest, [updateRequest])

  return (
    <VStack mt="1rem" align="flex-start">
      <Text>Filtrar</Text>
      <Autocomplete
        value={value}
        options={options}
        setValue={setValue}
        isMulti
      />
    </VStack>
  )
}

export default Filters

export const columns = [
  {
    Header: 'Nome',
    accessor: '',
  },
  {
    Header: 'Descrição',
    accessor: '',
  },
  {
    Header: '',
    accessor: '',
  },
  {
    Header: '',
    accessor: '',
  },
]

import { HStack } from '@chakra-ui/react'

import IRow from '../../types/IRow'

import View from './View'
import Delete from './Delete'

interface IProps {
  row: IRow
}

const Options: React.FC<IProps> = ({ row }) => {
  return (
    <HStack justify="flex-end" spacing={4}>
      <Delete row={row} />
      <View row={row} />
    </HStack>
  )
}

export default Options

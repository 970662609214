import { useCallback, useEffect, useState } from 'react'
import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import Button from 'components/Button'
import api from 'services/api'
import Autocomplete from 'components/Autocomplete'
import { FaPlus as AddSvg } from 'react-icons/fa'

interface IProps {
  row: any
  getBundles: any
}

const CreateButton: React.FC<IProps> = ({ row, getBundles }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [uf, setUf] = useState({ label: 'TODAS', value: '0' })
  const [city, setCity] = useState({ label: 'TODAS', value: '0' })
  const [channel, setChannel] = useState({ label: 'TODOS', value: '0' })

  const [ufOpt, setUfOpt] = useState<any[]>([{ label: 'TODAS', value: '0' }])
  const [cityOpt, setCityOpt] = useState<any[]>([
    { label: 'TODAS', value: '0' },
  ])
  const [channelOpt, setChannelOpt] = useState<any[]>([
    { label: 'TODAS', value: '0' },
  ])

  useEffect(() => {
    setUf({ label: 'TODAS', value: '0' })
    setCity({ label: 'TODAS', value: '0' })
    setChannel({ label: 'TODOS', value: '0' })
    setUfOpt([{ label: 'TODAS', value: '0' }])
    setCityOpt([{ label: 'TODAS', value: '0' }])
    setChannelOpt([{ label: 'TODAS', value: '0' }])
  }, [isOpen])

  const getUfs = useCallback(() => {
    isOpen &&
      api.get('/regions/ufs').then((res) => {
        setUfOpt([
          { label: 'TODAS', value: '0' },
          ...res.data.data.ufs.map((item: any) => ({
            label: item.code,
            value: item.code,
          })),
        ])
      })
  }, [isOpen])
  useEffect(getUfs, [getUfs])

  const getRegions = useCallback(() => {
    setCityOpt([])
    setCity({ label: 'TODAS', value: '0' })

    if (!isOpen || !uf.value) return

    api.get(`/regions/ufs/cities?uf=${uf.value}`).then((res) => {
      setCityOpt([
        { label: 'TODAS', value: '0' },
        ...res.data.data.cities.map((item: any) => ({
          label: item,
          value: item,
        })),
      ])
    })
  }, [isOpen, uf.value])
  useEffect(getRegions, [getRegions])

  const getChannels = useCallback(() => {
    isOpen &&
      api.get('/channels').then((res) => {
        setChannelOpt([
          { label: 'TODOS', value: '0' },
          ...res.data.data.channels.map((item: any) => ({
            label: item.name,
            value: item.id,
          })),
        ])
      })
  }, [isOpen])
  useEffect(getChannels, [getChannels])

  const onCreate = useCallback(() => {
    const request = {
      cluster_id: row.id,
      city: city.value,
      uf_code: uf.value,
      channel_id: channel.value,
    }

    api
      .post('/clusters/bundle', request)
      .then(() => {
        getBundles()
        onClose()
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
          isClosable: true,
        })
      })
      .catch((e) => {
        if (e.response.data.message.includes(':')) {
          return toast({
            title: `Já existe em${e.response.data.message.split(':')[1]}`,
            status: 'error',
            isClosable: true,
          })
        }

        toast({
          title: 'Erro ao adicionar, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [row.id, city.value, uf.value, channel.value, getBundles, onClose, toast])

  return (
    <>
      <HStack
        onClick={onOpen}
        w="100%"
        align="center"
        justify="center"
        my={4}
        py={4}
        spacing="0.5rem"
        color="green.500"
        cursor="pointer"
        borderRadius="1rem"
        _hover={{ bgColor: 'green.50' }}
      >
        <Text>Adicionar Cliente</Text>
        <AddSvg />
      </HStack>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeader>Adicionar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w="100%" spacing={6}>
              <VStack w="100%" align="flex-start">
                <Text>UF</Text>
                <Autocomplete value={uf} options={ufOpt} setValue={setUf} />
              </VStack>
              <VStack w="100%" align="flex-start">
                <Text>Cidade</Text>
                <Autocomplete
                  value={city}
                  options={cityOpt}
                  setValue={setCity}
                />
              </VStack>
              <VStack w="100%" align="flex-start">
                <Text>Canal</Text>
                <Autocomplete
                  value={channel}
                  options={channelOpt}
                  setValue={setChannel}
                />
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onCreate}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateButton

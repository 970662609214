import Panel from './Panel'
import Header from './Header'
import { Container } from './styles'

const PriceBaseCreate: React.FC = () => {
  return (
    <Container>
      <Header />
      <Panel />
    </Container>
  )
}

export default PriceBaseCreate

import AppBar from './AppBar'

import { Container, Main } from './styles'

interface IProps {
  children: Children
}

const Private: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <AppBar  />
      <Main>{children}</Main>
    </Container>
  )
}

export default Private

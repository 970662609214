import styled from 'styled-components'
import { Table as MUITable } from '@material-ui/core'

export const Table = styled(MUITable)`
  margin-bottom: 2rem;

  thead tr {
    background-color: #f2f3f5;
  }

  tbody {
    tr,
    td {
      transition: background-color 0.3s;
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem 1rem;

    color: #003b74;
    font-size: 1rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :first-child {
      width: 200px;
    }

    :nth-child(2) {
      width: 300px;
    }
  }

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    font-size: 1rem;
  }
`

import { IoPricetagsSharp as ParametersSvg } from 'react-icons/io5'

import PageTitle from 'components/PageTitle'

import { Container } from './styles'

const Header: React.FC = () => {
  return (
    <Container>
      <PageTitle
        icon={<ParametersSvg />}
        title="Gestão de Preços"
        linkLabel="Criação de Cenário"
      />
    </Container>
  )
}

export default Header

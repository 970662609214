import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from 'services/api'
import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'

import IFilters from '../types/IFilters'

import { cols } from './constants'
import { formatData } from './actions'

const limit = 13

interface IProps {
  filters: IFilters
  toggleBigData: () => void
}

const Table: React.FC<IProps> = ({ filters, toggleBigData }) => {
  const { id, type } = useParams()

  const [rows, setRows] = useState<Record<string, unknown>[]>([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPage] = useState(1)

  const ppvInputsRef = useRef<any[]>([])
  const discountInputsRef = useRef<any[]>([])
  const quantVendaInputsRef = useRef<any[]>([])

  useEffect(() => {
    ppvInputsRef.current = ppvInputsRef.current.slice(0, rows.length)
    discountInputsRef.current = discountInputsRef.current.slice(0, rows.length)
    quantVendaInputsRef.current = quantVendaInputsRef.current.slice(
      0,
      rows.length
    )
  }, [rows.length])

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [type, filters])

  const getRows = useCallback(() => {
    if (loading) {
      const url = `/discount-scenery/${id}/items?page=${page}&limit=${limit}&type=${type}`
      const request: { [key: string]: string[] } = {}

      Object.keys(filters).forEach((key) => {
        request[key] = filters[key]?.map((v: IOption) => v.value) || []
      })

      api.post(url, request).then((res) => {
        setRows(formatData(res.data.data.scenery_items))
        setTotal(res.data.data.total)
        setTotalPage(res.data.data.totalPages)
        setLoading(false)
      })
    }
  }, [filters, id, loading, page, type])

  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <TableBackend
        rows={rows}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        totalPages={totalPages}
        setPage={(newVal) => {
          setPage(newVal)
          setLoading(true)
        }}
        cellProps={{
          toggleBigData,
          ppvInputsRef,
          discountInputsRef,
          quantVendaInputsRef,
        }}
        stickyPos={7}
      />
    </Paper>
  )
}

export default memo(Table)

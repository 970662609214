import { Flex, AccordionIcon, AccordionButton, Text } from '@chakra-ui/react'

import Panel from './Panel'
import DeleteButton from './DeleteButton'
import { Container, Main } from './styles'

interface IRow {
  id: number | string
  name: string
  factory: string
}

interface IProps {
  row: IRow
}

const Row: React.FC<IProps> = ({ row }) => {
  return (
    <Container>
      {({ isExpanded }: any) => (
        <>
          <Main>
            <AccordionButton _expanded={{ bg: '#003b74', color: 'white' }}>
              <Flex flex="1" textAlign="left">
                <Text w="10rem">{row.factory}</Text>
                <Text w="10rem">{row.name}</Text>
              </Flex>
              <DeleteButton row={row} />
              <AccordionIcon />
            </AccordionButton>
          </Main>
          <Panel row={row} isExpanded={isExpanded} />
        </>
      )}
    </Container>
  )
}

export default Row

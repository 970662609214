import { useCallback, Fragment } from 'react'
import { Text } from '@chakra-ui/react'

import Autocomplete from 'components/Autocomplete'

import nloop from '../nloop'
import INiveisIniState from '../reducer/types/INiveisIniState'

interface IProps {
  niveis: INiveisIniState
  setNivel: (data: { [n: string]: IOption[] }) => void
}

const Niveis: React.FC<IProps> = ({
  niveis,

  setNivel,
}) => {
  const setValue = useCallback(
    (newVal: IOption[], key: string) => {
      const data: { [n: string]: IOption[] } = {}

      nloop.forEach((n) => {
        if (n > key) return (data[n] = [])
        if (n === key) return (data[n] = newVal)
        data[n] = niveis[n]
      })

      setNivel(data)
    },
    [niveis, setNivel]
  )

  return (
    <>
      {nloop.map((n) => (
        <Fragment key={n}>
          <Text>Nível 0{n}</Text>
          <Autocomplete
            isMulti
            value={niveis[n]}
            options={niveis[`${n}opt`]}
            setValue={(newVal: IOption[]) => setValue(newVal, n)}
          />
        </Fragment>
      ))}
    </>
  )
}

export default Niveis
